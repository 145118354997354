export class UserCreate {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    role: string;
    userMetadata?: UserMetadata;
}

class UserMetadata {
    language: string;
    clinicIds: number[];
}

export enum UserRole {
    POMELO_ADMIN = 'POMELO_ADMIN',
    CLINIC_ADMIN = 'CLINIC_ADMIN',
    CLINIC_STAFF = 'CLINIC_STAFF',
}
