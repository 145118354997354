<div
    [ngClass]="{
        'ui-calendar': true,
        'ui-calendar-w-btn': showIcon,
        'ui-calendar-timeonly': timeOnly
    }"
    [ngStyle]="style"
    [class]="styleClass"
>
    <ng-template [ngIf]="!inline">
        <input
            #inputfield
            type="text"
            [attr.id]="inputId"
            [attr.name]="name"
            [attr.required]="required"
            [value]="inputFieldValue"
            (focus)="onInputFocus($event)"
            (keydown)="onInputKeydown($event)"
            (click)="onInputClick($event)"
            (blur)="onInputBlur($event)"
            [readonly]="readonlyInput"
            (input)="onUserInput($event)"
            [ngStyle]="inputStyle"
            [class]="inputStyleClass"
            [placeholder]="placeholder || ''"
            [disabled]="disabled"
            [attr.tabindex]="tabindex"
            [ngClass]="'ui-inputtext ui-widget ui-state-default ui-corner-all'"
            autocomplete="off"
        />
        <button
            type="button"
            [icon]="icon"
            pButton
            *ngIf="showIcon"
            (click)="onButtonClick($event, inputfield)"
            class="ui-datepicker-trigger ui-calendar-button"
            [ngClass]="{ 'ui-state-disabled': disabled }"
            [disabled]="disabled"
            tabindex="-1"
        ></button>
    </ng-template>
    <div
        [class]="panelStyleClass"
        [ngStyle]="panelStyle"
        [ngClass]="{
            'ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all': true,
            'ui-datepicker-inline': inline,
            'ui-shadow': !inline,
            'ui-state-disabled': disabled,
            'ui-datepicker-timeonly': timeOnly,
            'ui-datepicker-multiple-month': this.numberOfMonths > 1,
            'ui-datepicker-monthpicker': view === 'month',
            'ui-datepicker-touch-ui': touchUI
        }"
        [@overlayAnimation]="
            touchUI
                ? {
                      value: 'visibleTouchUI',
                      params: {
                          showTransitionParams: showTransitionOptions,
                          hideTransitionParams: hideTransitionOptions
                      }
                  }
                : {
                      value: 'visible',
                      params: {
                          showTransitionParams: showTransitionOptions,
                          hideTransitionParams: hideTransitionOptions
                      }
                  }
        "
        [@.disabled]="inline === true"
        (@overlayAnimation.start)="onOverlayAnimationStart($event)"
        (@overlayAnimation.done)="onOverlayAnimationDone($event)"
        *ngIf="inline || overlayVisible"
    >
        <ng-content select="p-header"></ng-content>
        <ng-container *ngIf="!timeOnly">
            <div class="ui-datepicker-group ui-widget-content" *ngFor="let month of months; let i = index">
                <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
                    <a class="ui-datepicker-prev ui-corner-all" (click)="navBackward($event)" *ngIf="i === 0">
                        <span class="ui-datepicker-prev-icon pi pi-chevron-left"></span>
                    </a>
                    <a class="ui-datepicker-next ui-corner-all" (click)="navForward($event)" *ngIf="numberOfMonths === 1 ? true : i === numberOfMonths - 1">
                        <span class="ui-datepicker-next-icon pi pi-chevron-right"></span>
                    </a>
                    <div class="ui-datepicker-title">
                        <span class="ui-datepicker-month" *ngIf="!monthNavigator && view !== 'month'">{{ locale.monthNames[month.month] }}</span>
                        <select
                            class="ui-datepicker-month"
                            *ngIf="monthNavigator && view !== 'month' && numberOfMonths === 1"
                            (change)="onMonthDropdownChange($event.target.value)"
                        >
                            <option [value]="i" *ngFor="let monthName of locale.monthNames; let i = index" [selected]="i === month.month">
                                {{ monthName }}
                            </option>
                        </select>
                        <select class="ui-datepicker-year" *ngIf="yearNavigator && numberOfMonths === 1" (change)="onYearDropdownChange($event.target.value)">
                            <option [value]="year" *ngFor="let year of yearOptions" [selected]="year === currentYear">
                                {{ year }}
                            </option>
                        </select>
                        <span class="ui-datepicker-year" *ngIf="!yearNavigator">{{ view === 'month' ? currentYear : month.year }}</span>
                    </div>
                </div>
                <div class="ui-datepicker-calendar-container" *ngIf="view === 'date'">
                    <table class="ui-datepicker-calendar">
                        <thead>
                            <tr>
                                <th *ngIf="showWeek" class="ui-datepicker-weekheader">
                                    <span>{{ locale['weekHeader'] }}</span>
                                </th>
                                <th scope="col" *ngFor="let weekDay of weekDays; let begin = first; let end = last">
                                    <span>{{ weekDay }}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let week of month.dates; let i = index">
                                <td *ngIf="showWeek" class="ui-datepicker-weeknumber ui-state-disabled">
                                    <span>
                                        {{ month.weekNumbers[i] }}
                                    </span>
                                </td>
                                <td
                                    *ngFor="let date of week"
                                    [ngClass]="{
                                        'ui-datepicker-other-month': date.otherMonth,
                                        'ui-datepicker-current-day': isSelected(date),
                                        'ui-datepicker-today': date.today
                                    }"
                                >
                                    <ng-container *ngIf="date.otherMonth ? showOtherMonths : true">
                                        <a
                                            class="ui-state-default"
                                            *ngIf="date.selectable"
                                            [ngClass]="{
                                                'ui-state-active': isSelected(date),
                                                'ui-state-highlight': date.today
                                            }"
                                            (click)="onDateSelect($event, date)"
                                            draggable="false"
                                        >
                                            <ng-container *ngIf="!dateTemplate">{{ date.day }}</ng-container>
                                            <ng-container *ngTemplateOutlet="dateTemplate; context: { $implicit: date }"></ng-container>
                                        </a>
                                        <span
                                            class="ui-state-default ui-state-disabled"
                                            [ngClass]="{
                                                'ui-state-active': isSelected(date),
                                                'ui-state-highlight': date.today
                                            }"
                                            *ngIf="!date.selectable"
                                        >
                                            {{ date.day }}
                                        </span>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="ui-monthpicker" *ngIf="view === 'month'">
                <a
                    tabindex="0"
                    *ngFor="let m of monthPickerValues; let i = index"
                    (click)="onMonthSelect($event, i)"
                    class="ui-monthpicker-month"
                    [ngClass]="{ 'ui-state-active': isMonthSelected(i) }"
                >
                    {{ m }}
                </a>
            </div>
        </ng-container>

        <!-- TIME PICKER -->
        <div class="ui-timepicker ui-widget-header ui-corner-all" *ngIf="showTime || timeOnly">
            <div class="ui-hour-picker picker-col">
                <p class="current-hour-wrap">
                    <span
                        [ngStyle]="{
                            display: currentHour < 10 ? 'inline' : 'none'
                        }"
                    >
                        0
                    </span>
                    {{ currentHour }}
                </p>
            </div>

            <div class="picker-navigation-wrap picker-col">
                <a tabindex="0" (mousedown)="onTimePickerElementMouseDown($event, 0, 1)" (mouseup)="onTimePickerElementMouseUp($event)" class="icon-chevron-up"></a>
                <a tabindex="0" (mousedown)="onTimePickerElementMouseDown($event, 0, -1)" (mouseup)="onTimePickerElementMouseUp($event)" class="icon-chevron-down"></a>
            </div>
            <!-- <div class="ui-separator picker-col">
                <a tabindex="0"><span class="pi pi-chevron-up"></span></a>
                <span>{{timeSeparator}}</span>
                <a tabindex="0"><span class="pi pi-chevron-down"></span></a>
            </div> -->
            <div class="ui-minute-picker picker-col">
                <p class="current-hour-wrap">
                    <span
                        [ngStyle]="{
                            display: currentMinute < 10 ? 'inline' : 'none'
                        }"
                    >
                        0
                    </span>
                    {{ currentMinute }}
                </p>
            </div>
            <div class="picker-navigation-wrap picker-col">
                <a tabindex="0" (mousedown)="onTimePickerElementMouseDown($event, 1, 1)" (mouseup)="onTimePickerElementMouseUp($event)" class="icon-chevron-up"></a>
                <a tabindex="0" (mousedown)="onTimePickerElementMouseDown($event, 1, -1)" (mouseup)="onTimePickerElementMouseUp($event)" class="icon-chevron-down"></a>
            </div>

            <span *ngIf="showSeconds">
                <!-- <div class="ui-separator picker-col">
                    <a tabindex="0"><span class="pi pi-chevron-up"></span></a>
                    <span>{{timeSeparator}}</span>
                    <a tabindex="0"><span class="pi pi-chevron-down"></span></a>
                </div> -->
                <div class="ui-second-picker picker-col">
                    <p class="current-hour-wrap">
                        <span
                            [ngStyle]="{
                                display: currentSecond < 10 ? 'inline' : 'none'
                            }"
                        >
                            0
                        </span>
                        {{ currentSecond }}
                    </p>
                </div>
                <div class="picker-navigation-wrap picker-col">
                    <a tabindex="0" (mousedown)="onTimePickerElementMouseDown($event, 2, 1)" (mouseup)="onTimePickerElementMouseUp($event)" class="icon-chevron-up"></a>
                    <a tabindex="0" (mousedown)="onTimePickerElementMouseDown($event, 2, -1)" (mouseup)="onTimePickerElementMouseUp($event)" class="icon-chevron-down"></a>
                </div>
            </span>

            <div class="ui-ampm-picker picker-col" *ngIf="hourFormat === '12'">
                <a tabindex="0" (click)="toggleAMPM($event)" [ngClass]="{ active: !pm }">AM</a>
                <a tabindex="0" (click)="toggleAMPM($event)" [ngClass]="{ active: pm }">PM</a>
            </div>
        </div>

        <div class="ui-datepicker-buttonbar ui-widget-header" *ngIf="showButtonBar">
            <div class="ui-g">
                <div class="ui-g-6">
                    <button type="button" [label]="_locale.today" (click)="onTodayButtonClick($event)" pButton [ngClass]="[todayButtonStyleClass]"></button>
                </div>
                <div class="ui-g-6">
                    <button type="button" [label]="_locale.clear" (click)="onClearButtonClick($event)" pButton [ngClass]="[clearButtonStyleClass]"></button>
                </div>
            </div>
        </div>
        <ng-content select="p-footer"></ng-content>
    </div>
</div>
