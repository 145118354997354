<div
    class="section"
    [ngClass]="sectionClass || null"
    [attr.data-bg-color]="bgColor || null"
    [attr.data-is-wrapping]="isWrapping || null"
    [attr.data-section-no-left]="noLeft || null"
    [attr.data-section-no-right]="noRight || null"
>
    <div class="section__content">
        <ng-content></ng-content>
    </div>
</div>
