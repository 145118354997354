<div
    #container
    [ngClass]="{
        'ui-dropdown ui-widget ui-state-default ui-corner-all ui-helper-clearfix': true,
        'ui-state-disabled': disabled,
        'ui-dropdown-open': overlayVisible,
        'ui-state-focus': focused,
        'ui-dropdown-clearable': showClear && !disabled
    }"
    (click)="onMouseclick($event)"
    [ngStyle]="style"
    [class]="styleClass"
>
    <div class="ui-helper-hidden-accessible">
        <input
            #in
            [attr.id]="inputId"
            type="text"
            [attr.aria-label]="selectedOption ? selectedOption.label : ' '"
            aria-haspopup="listbox"
            (focus)="onInputFocus($event)"
            (blur)="onInputBlur($event)"
            (keydown)="onKeydown($event, true)"
            [disabled]="disabled"
            [attr.tabindex]="tabindex"
            [attr.autofocus]="autofocus"
            readonly
        />
    </div>

    <label
        *ngIf="!editable && label != null"
        [ngClass]="{
            'ui-dropdown-label ui-inputtext': true,
            'ui-dropdown-label-empty': label == null || label.length === 0
        }"
    >
        <ng-container *ngIf="!selectedItemTemplate">{{ label || 'empty' }}</ng-container>
        <ng-container *ngTemplateOutlet="selectedItemTemplate; context: { $implicit: selectedOption }"></ng-container>
    </label>

    <label
        [ngClass]="{
            'ui-dropdown-label ui-inputtext ui-placeholder': true,
            'ui-dropdown-label-empty': placeholder == null || placeholder.length === 0
        }"
        *ngIf="!editable && label == null"
    >
        {{ placeholder || 'empty' }}
    </label>

    <input
        #editableInput
        type="text"
        [attr.aria-label]="selectedOption ? selectedOption.label : ' '"
        class="ui-dropdown-label ui-inputtext"
        *ngIf="editable"
        [disabled]="disabled"
        [attr.placeholder]="placeholder"
        (click)="onEditableInputClick($event)"
        (input)="onEditableInputChange($event)"
        (focus)="onEditableInputFocus($event)"
        (blur)="onInputBlur($event)"
    />

    <i class="ui-dropdown-clear-icon pi pi-times" (click)="clear($event)" *ngIf="value != null && showClear && !disabled"></i>

    <div class="ui-dropdown-trigger ui-state-default">
        <span class="ui-dropdown-trigger-icon ui-clickable" [ngClass]="dropdownIcon"></span>
    </div>

    <div
        *ngIf="overlayVisible"
        [ngClass]="'ui-dropdown-panel ui-widget ui-widget-content ui-shadow'"
        [@overlayAnimation]="{
            value: 'visible',
            params: {
                showTransitionParams: showTransitionOptions,
                hideTransitionParams: hideTransitionOptions
            }
        }"
        (@overlayAnimation.start)="onOverlayAnimationStart($event)"
        [ngStyle]="panelStyle"
        [class]="panelStyleClass"
    >
        <div *ngIf="filter" class="ui-dropdown-filter-container" (click)="$event.stopPropagation()">
            <input
                #filter
                type="text"
                autocomplete="off"
                [value]="filterValue || ''"
                class="ui-dropdown-filter ui-inputtext ui-widget ui-state-default"
                [attr.placeholder]="filterPlaceholder"
                (keydown.enter)="$event.preventDefault()"
                (keydown)="onKeydown($event, false)"
                (input)="onFilter($event)"
                [attr.aria-label]="ariaFilterLabel"
            />

            <span class="ui-dropdown-filter-icon pi pi-search"></span>
        </div>

        <div class="ui-dropdown-items-wrapper" [style.max-height]="virtualScroll ? 'auto' : scrollHeight || 'auto'">
            <ul class="ui-dropdown-items ui-dropdown-list ui-widget-content ui-widget ui-helper-reset" role="listbox">
                <ng-container *ngIf="group">
                    <ng-template ngFor let-optgroup [ngForOf]="optionsToDisplay">
                        <li class="ui-dropdown-item-group">
                            <span *ngIf="!groupTemplate">{{ optgroup.label || 'empty' }}</span>
                            <ng-container *ngTemplateOutlet="groupTemplate; context: { $implicit: optgroup }"></ng-container>
                        </li>
                        <ng-container
                            *ngTemplateOutlet="
                                itemslist;
                                context: {
                                    $implicit: optgroup.items,
                                    selectedOption: selectedOption
                                }
                            "
                        ></ng-container>
                    </ng-template>
                </ng-container>
                <ng-container *ngIf="!group">
                    <ng-container
                        *ngTemplateOutlet="
                            itemslist;
                            context: {
                                $implicit: optionsToDisplay,
                                selectedOption: selectedOption
                            }
                        "
                    ></ng-container>
                </ng-container>
                <ng-template #itemslist let-options let-selectedOption="selectedOption">
                    <ng-container *ngIf="!virtualScroll; else virtualScrollList">
                        <ng-template ngFor let-option let-i="index" [ngForOf]="options">
                            <app-dropdown-item
                                [option]="option"
                                [selected]="selectedOption == option"
                                (onClick)="onItemClick($event)"
                                [template]="itemTemplate"
                            ></app-dropdown-item>
                        </ng-template>
                    </ng-container>
                    <ng-template #virtualScrollList>
                        <cdk-virtual-scroll-viewport
                            #viewport
                            [ngStyle]="{ height: scrollHeight }"
                            [itemSize]="itemSize"
                            *ngIf="virtualScroll && optionsToDisplay && optionsToDisplay.length"
                        >
                            <ng-container *cdkVirtualFor="let option of options; let i = index; let c = count; let f = first; let l = last; let e = even; let o = odd">
                                <app-dropdown-item
                                    [option]="option"
                                    [selected]="selectedOption == option"
                                    (onClick)="onItemClick($event)"
                                    [template]="itemTemplate"
                                ></app-dropdown-item>
                            </ng-container>
                        </cdk-virtual-scroll-viewport>
                    </ng-template>
                </ng-template>

                <li *ngIf="filter && optionsToDisplay && optionsToDisplay.length === 0" class="ui-dropdown-empty-message">
                    {{ emptyFilterMessage }}
                </li>
            </ul>
        </div>
    </div>
</div>
