import {
    Component,
    ElementRef,
    OnInit,
    AfterViewInit,
    AfterContentInit,
    AfterViewChecked,
    OnDestroy,
    Input,
    Output,
    Renderer2,
    EventEmitter,
    forwardRef,
    ViewChild,
    ChangeDetectorRef,
    TemplateRef,
    ContentChildren,
    QueryList,
    ContentChild,
} from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';

import { SelectItem } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { ObjectUtils } from 'primeng/utils';
import { PrimeTemplate, Footer, Header } from 'primeng/api';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { FilterUtils } from 'primeng/utils';

export const MULTISELECT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectComponent),
    multi: true,
};

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    animations: [
        trigger('overlayAnimation', [
            state(
                'void',
                style({
                    transform: 'translateY(5%)',
                    opacity: 0,
                })
            ),
            state(
                'visible',
                style({
                    transform: 'translateY(0)',
                    opacity: 1,
                })
            ),
            transition('void => visible', animate('{{showTransitionParams}}')),
            transition('visible => void', animate('{{hideTransitionParams}}')),
        ]),
    ],
    host: {
        '[class.ui-inputwrapper-filled]': 'filled',
        '[class.ui-inputwrapper-focus]': 'focus',
    },
    providers: [MULTISELECT_VALUE_ACCESSOR],
})
export class MultiSelectComponent implements OnInit, AfterViewInit, AfterContentInit, AfterViewChecked, OnDestroy, ControlValueAccessor {
    @Input() scrollHeight = '200px';

    labelDefault = 'Choose';

    @Input() set defaultLabel(val: string) {
        this.labelDefault = val;
        this.updateLabel();
    }

    get defaultLabel(): string {
        return this.labelDefault;
    }

    @Input() style: any;
    @Input() styleClass: string;
    @Input() panelStyle: any;
    @Input() panelStyleClass: string;
    @Input() inputId: string;
    @Input() disabled: boolean;
    @Input() readonly: boolean;
    @Input() filter = true;
    @Input() filterPlaceHolder: string;
    @Input() overlayVisible: boolean;
    @Input() tabindex: number;
    @Input() appendTo: any;
    @Input() dataKey: string;
    @Input() name: string;
    @Input() ariaLabelledBy: string;
    @Input() displaySelectedLabel = true;
    @Input() maxSelectedLabels = 3;
    @Input() selectionLimit: number;
    @Input() selectedItemsLabel = '{0} items selected';
    @Input() showToggleAll = true;
    @Input() emptyFilterMessage = 'No results found';
    @Input() resetFilterOnHide = false;
    @Input() dropdownIcon = 'pi pi-chevron-down';
    @Input() optionLabel: string;
    @Input() showHeader = true;
    @Input() autoZIndex = true;
    @Input() baseZIndex = 0;
    @Input() filterBy = 'label';
    @Input() virtualScroll: boolean;
    @Input() itemSize: number;
    @Input() showTransitionOptions = '225ms ease-out';
    @Input() hideTransitionOptions = '195ms ease-in';
    @Input() ariaFilterLabel: string;
    @Input() filterMatchMode = 'contains';
    @Input() tooltip = '';
    @Input() tooltipPosition = 'right';
    @Input() tooltipPositionStyle = 'absolute';
    @Input() tooltipStyleClass: string;
    @Input() selectAllLabel: string;
    @Input() selectCountLabel: string;

    @ViewChild('container') containerViewChild: ElementRef;
    @ViewChild('filterInput') filterInputChild: ElementRef;
    @ContentChild(Footer) footerFacet;
    @ContentChild(Header, { static: true }) headerFacet;
    @ContentChildren(PrimeTemplate) templates: QueryList<any>;

    // tslint:disable:no-output-on-prefix
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Output() onFocus: EventEmitter<any> = new EventEmitter();
    @Output() onBlur: EventEmitter<any> = new EventEmitter();
    @Output() onClick: EventEmitter<any> = new EventEmitter();
    @Output() onPanelShow: EventEmitter<any> = new EventEmitter();
    @Output() onPanelHide: EventEmitter<any> = new EventEmitter();

    public value: any[];

    overlay: HTMLDivElement;

    public valuesAsString: string;

    public focus: boolean;

    filled: boolean;

    public documentClickListener: any;

    public selfClick: boolean;

    public panelClick: boolean;

    public filterValue: string;

    public visibleOptions: SelectItem[];

    public filtered: boolean;

    public itemTemplate: TemplateRef<any>;

    public selectedItemsTemplate: TemplateRef<any>;

    public headerCheckboxFocus: boolean;

    opts: any[];

    maxSelectionLimitReached: boolean;

    documentResizeListener: any;

    public onModelChange: (value) => void = () => {};

    public onModelTouched: () => void = () => {};

    constructor(public el: ElementRef, public renderer: Renderer2, private cd: ChangeDetectorRef) {}

    @Input() get options(): any[] {
        return this.opts;
    }

    set options(val: any[]) {
        const opts = this.optionLabel ? ObjectUtils.generateSelectItems(val, this.optionLabel) : val;
        this.visibleOptions = opts;
        this.opts = opts;
        this.updateLabel();
    }

    ngOnInit() {
        this.updateLabel();
    }

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'item':
                    this.itemTemplate = item.template;
                    break;

                case 'selectedItems':
                    this.selectedItemsTemplate = item.template;
                    break;

                default:
                    this.itemTemplate = item.template;
                    break;
            }
        });
    }

    ngAfterViewInit() {
        if (this.overlayVisible) {
            this.show();
        }
    }

    ngAfterViewChecked() {
        if (this.filtered) {
            this.alignOverlay();

            this.filtered = false;
        }
    }

    writeValue(value: any): void {
        this.value = value;
        this.updateLabel();
        this.updateFilledState();
        this.cd.markForCheck();
    }

    updateFilledState() {
        this.filled = this.valuesAsString !== null && this.valuesAsString.length > 0;
    }

    registerOnChange(fn: () => void): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onModelTouched = fn;
    }

    setDisabledState(val: boolean): void {
        this.disabled = val;
    }

    onOptionClick(event) {
        const option = event.option;
        if (option.disabled) {
            return;
        }

        const optionValue = option.value;
        const selectionIndex = this.findSelectionIndex(optionValue);
        if (selectionIndex !== -1) {
            this.value = this.value.filter((val, i) => i !== selectionIndex);

            if (this.selectionLimit) {
                this.maxSelectionLimitReached = false;
            }
        } else {
            if (!this.selectionLimit || !this.value || this.value.length < this.selectionLimit) {
                this.value = [...(this.value || []), optionValue];
            }

            if (this.selectionLimit && (!this.value || this.value.length === this.selectionLimit)) {
                this.maxSelectionLimitReached = true;
            }
        }

        this.onModelChange(this.value);
        this.onChange.emit({
            originalEvent: event.originalEvent,
            value: this.value,
            itemValue: optionValue,
        });
        this.updateLabel();
        this.updateFilledState();
    }

    isSelected(value) {
        return this.findSelectionIndex(value) !== -1;
    }

    findSelectionIndex(val: any): number {
        let index = -1;

        if (this.value) {
            for (let i = 0; i < this.value.length; i++) {
                if (ObjectUtils.equals(this.value[i], val, this.dataKey)) {
                    index = i;
                    break;
                }
            }
        }

        return index;
    }

    toggleAll(event: Event) {
        if (this.isAllChecked()) {
            this.value = [];
        } else {
            const opts = this.getVisibleOptions();
            if (opts) {
                this.value = [];
                for (const opt of opts) {
                    if (!opt.disabled) {
                        this.value.push(opt.value);
                    }
                }
            }
        }

        this.onModelChange(this.value);
        this.onChange.emit({ originalEvent: event, value: this.value });
        this.updateLabel();
    }

    isAllChecked() {
        if (this.filterValue && this.filterValue.trim().length) {
            return this.value && this.visibleOptions && this.visibleOptions.length && this.isAllVisibleOptionsChecked();
        } else {
            const optionCount = this.getEnabledOptionCount();

            return this.value && this.options && this.value.length > 0 && this.value.length === optionCount;
        }
    }

    isAllVisibleOptionsChecked() {
        if (!this.visibleOptions) {
            return false;
        } else {
            for (const option of this.visibleOptions) {
                if (!this.isSelected(option.value)) {
                    return false;
                }
            }
            return true;
        }
    }

    getEnabledOptionCount(): number {
        if (this.options) {
            let count = 0;
            for (const opt of this.options) {
                if (!opt.disabled) {
                    count++;
                }
            }

            return count;
        } else {
            return 0;
        }
    }

    show() {
        if (!this.overlayVisible) {
            this.overlayVisible = true;
        }

        if (this.filter) {
            setTimeout(() => {
                if (this.filterInputChild !== undefined) {
                    this.filterInputChild.nativeElement.focus();
                }
            }, 200);
        }
        this.bindDocumentClickListener();
    }

    onOverlayAnimationStart(event: AnimationEvent) {
        switch (event.toState) {
            case 'visible':
                this.overlay = event.element;
                this.appendOverlay();
                if (this.autoZIndex) {
                    this.overlay.style.zIndex = String(this.baseZIndex + ++DomHandler.zindex);
                }
                this.alignOverlay();
                this.bindDocumentClickListener();
                this.bindDocumentResizeListener();
                this.onPanelShow.emit();
                break;

            case 'void':
                this.onOverlayHide();
                break;
        }
    }

    appendOverlay() {
        if (this.appendTo) {
            if (this.appendTo === 'body') {
                document.body.appendChild(this.overlay);
            } else {
                DomHandler.appendChild(this.overlay, this.appendTo);
            }

            this.overlay.style.minWidth = DomHandler.getWidth(this.containerViewChild.nativeElement) + 'px';
        }
    }

    restoreOverlayAppend() {
        if (this.overlay && this.appendTo) {
            this.el.nativeElement.appendChild(this.overlay);
        }
    }

    alignOverlay() {
        if (this.overlay) {
            if (this.appendTo) {
                DomHandler.absolutePosition(this.overlay, this.containerViewChild.nativeElement);
            } else {
                DomHandler.relativePosition(this.overlay, this.containerViewChild.nativeElement);
            }
        }
    }

    hide() {
        this.overlayVisible = false;
        this.unbindDocumentClickListener();
        if (this.resetFilterOnHide) {
            this.filterInputChild.nativeElement.value = '';
            this.onFilter();
        }
        this.onPanelHide.emit();
    }

    close(event) {
        this.hide();
        event.preventDefault();
        event.stopPropagation();
    }

    onMouseclick(event, input) {
        if (this.disabled || this.readonly) {
            return;
        }

        this.onClick.emit(event);

        if (!this.panelClick) {
            if (this.overlayVisible) {
                this.hide();
            } else {
                input.focus();
                this.show();
            }
        }

        this.selfClick = true;
    }

    onInputFocus(event) {
        this.focus = true;
        this.onFocus.emit({ originalEvent: event });
    }

    onInputBlur(event) {
        this.focus = false;
        this.onBlur.emit({ originalEvent: event });
        this.onModelTouched();
    }

    onOptionKeydown(event) {
        if (this.readonly) {
            return;
        }

        switch (event.originalEvent.which) {
            // down
            case 40:
                const nextItem = this.findNextItem(event.originalEvent);
                if (nextItem) {
                    nextItem.focus();
                }

                event.originalEvent.preventDefault();
                break;

            // up
            case 38:
                const prevItem = this.findPrevItem(event.originalEvent);
                if (prevItem) {
                    prevItem.focus();
                }

                event.originalEvent.preventDefault();
                break;

            // enter
            case 13:
                this.onOptionClick(event);
                event.originalEvent.preventDefault();
                break;
        }
    }

    findNextItem(event) {
        const nextItem = event.target.parentElement.nextElementSibling;

        if (nextItem) {
            return DomHandler.hasClass(nextItem.children[0], 'ui-state-disabled') || DomHandler.isHidden(nextItem.children[0])
                ? this.findNextItem(nextItem.children[0])
                : nextItem.children[0];
        } else {
            return null;
        }
    }

    findPrevItem(event) {
        const prevItem = event.target.parentElement.previousElementSibling;

        if (prevItem) {
            return DomHandler.hasClass(prevItem.children[0], 'ui-state-disabled') || DomHandler.isHidden(prevItem) ? this.findPrevItem(prevItem.children[0]) : prevItem.children[0];
        } else {
            return null;
        }
    }

    onKeydown(event: KeyboardEvent) {
        switch (event.which) {
            // down
            case 40:
                if (!this.overlayVisible && event.altKey) {
                    this.show();
                }
                break;

            // space
            case 32:
                if (!this.overlayVisible) {
                    this.show();
                    event.preventDefault();
                }
                break;

            // escape
            case 27:
                this.hide();
                break;
        }
    }

    updateLabel() {
        if (this.value && this.options && this.value.length && this.displaySelectedLabel) {
            let label = '';
            for (const value of this.value) {
                const itemLabel = this.findLabelByValue(value);
                if (itemLabel) {
                    if (label.length > 0) {
                        label = label + ', ';
                    }
                    label = label + itemLabel;
                }
            }

            if (this.value.length <= this.maxSelectedLabels) {
                this.valuesAsString = label;
            } else {
                const pattern = /{(.*?)}/;
                if (pattern.test(this.selectedItemsLabel)) {
                    this.valuesAsString = this.selectedItemsLabel.replace(this.selectedItemsLabel.match(pattern)[0], this.value.length + '');
                }
            }
        } else {
            this.valuesAsString = this.defaultLabel;
        }
    }

    findLabelByValue(val: any): string {
        let label = null;
        for (const option of this.options) {
            if ((val == null && option.value == null) || ObjectUtils.equals(val, option.value, this.dataKey)) {
                label = option.label;
                break;
            }
        }
        return label;
    }

    onFilter() {
        const inputValue = this.filterInputChild.nativeElement.value;
        if (inputValue && inputValue.length) {
            this.filterValue = inputValue;
            this.activateFilter();
        } else {
            this.filterValue = null;
            this.visibleOptions = this.options;
            this.filtered = false;
        }
    }

    activateFilter() {
        if (this.options && this.options.length) {
            const searchFields: string[] = this.filterBy.split(',');
            this.visibleOptions = FilterUtils.filter(this.options, searchFields, this.filterValue, this.filterMatchMode);
            this.filtered = true;
        }
    }

    isItemVisible(option: SelectItem): boolean {
        if (this.filterValue && this.filterValue.trim().length) {
            for (const visibleOption of this.visibleOptions) {
                if (visibleOption.value === option.value) {
                    return true;
                }
            }
        } else {
            return true;
        }
    }

    getVisibleOptions(): SelectItem[] {
        if (this.visibleOptions && this.visibleOptions.length) {
            return this.visibleOptions;
        } else {
            return this.options;
        }
    }

    onHeaderCheckboxFocus() {
        this.headerCheckboxFocus = true;
    }

    onHeaderCheckboxBlur() {
        this.headerCheckboxFocus = false;
    }

    bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.documentClickListener = this.renderer.listen('document', 'click', () => {
                if (!this.selfClick && !this.panelClick && this.overlayVisible) {
                    this.hide();
                }

                this.selfClick = false;
                this.panelClick = false;
                this.cd.markForCheck();
            });
        }
    }

    unbindDocumentClickListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }

    bindDocumentResizeListener() {
        this.documentResizeListener = this.onWindowResize.bind(this);
        window.addEventListener('resize', this.documentResizeListener);
    }

    unbindDocumentResizeListener() {
        if (this.documentResizeListener) {
            window.removeEventListener('resize', this.documentResizeListener);
            this.documentResizeListener = null;
        }
    }

    onWindowResize() {
        if (!DomHandler.isAndroid()) {
            this.hide();
        }
    }

    onOverlayHide() {
        this.unbindDocumentClickListener();
        this.unbindDocumentResizeListener();
        this.overlay = null;
    }

    ngOnDestroy() {
        this.restoreOverlayAppend();
        this.onOverlayHide();
    }
}
