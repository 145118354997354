import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, OnDestroy, NgZone, AfterViewInit } from '@angular/core';
import { Address } from '../../models/Clinic';

declare var google: any;

@Component({
    selector: 'app-address-autocomplete',
    templateUrl: './address-autocomplete.component.html',
    styleUrls: ['./address-autocomplete.component.scss'],
})
export class AddressAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('address') addresstext: ElementRef;
    @ViewChild('mapContainer') gmap: ElementRef;

    @Output() addressChange = new EventEmitter();
    @Output() currentTimeZone = new EventEmitter();

    formattedAddr: string;
    isAutocomplete = false;
    geocomplete: any;

    private extractedStreetNumber: string;
    private address: Address;

    constructor(private ngZone: NgZone) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.initGeocomplete();
    }

    initGeocomplete() {
        this.geocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, { types: ['address'] });
        this.geocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
                const place = this.geocomplete.getPlace();

                if (place.formatted_address) {
                    this.onAddressChange(place);
                }
            });
        });

        // Disabling Chrome Autofill
        // https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3
        const observer = new MutationObserver(() => {
            observer.disconnect();
            this.addresstext.nativeElement.setAttribute('autocomplete', 'new-address');
        });

        observer.observe(this.addresstext.nativeElement, {
            attributes: true,
            attributeFilter: ['autocomplete'],
        });
    }

    initAndShowMap(lat: string, lng: string) {
        const coordinates = new google.maps.LatLng(lat, lng);

        const googlemap = new google.maps.Map(this.gmap.nativeElement, {
            center: coordinates,
            zoom: 10,
            panControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            rotateControl: false,
        });

        const marker = new google.maps.Marker({
            position: coordinates,
            map: googlemap,
        });

        marker.setMap(googlemap);

        this.isAutocomplete = true;
    }

    onAddressChange(place: any) {
        this.formatAddress(place);

        this.addressChange.emit(this.address);
        this.currentTimeZone.emit(place.utc_offset_minutes);

        this.initAndShowMap(this.address.xcoords, this.address.zcoords);
    }

    private formatAddress(place: any) {
        this.address = {
            streetNumber: this.checkStreetNbrExist(this.getAddrComponent(place, { street_number: 'long_name' })),
            streetName: this.getAddrComponent(place, { route: 'long_name' }),
            officeNumber: '',
            city: this.getAddrComponent(place, { locality: 'long_name' }),
            province: this.getAddrComponent(place, {
                administrative_area_level_1: 'short_name',
            }),
            postalCode: this.getAddrComponent(place, {
                postal_code: 'long_name',
            })
                ? this.getAddrComponent(place, { postal_code: 'long_name' })
                : '',
            country: this.getAddrComponent(place, { country: 'short_name' }),
            xcoords: place.geometry.location.lat().toString(),
            zcoords: place.geometry.location.lng().toString(),
            formatted: place.formatted_address,
        };

        if (this.extractedStreetNumber !== '') {
            this.address.formatted = `${this.extractedStreetNumber} ${this.address.formatted}`;
        }
    }

    private checkStreetNbrExist(nbr: string): string {
        if (nbr) {
            this.extractedStreetNumber = '';
            return nbr;
        } else {
            const extractNbr = this.formattedAddr.split(' ');
            return (this.extractedStreetNumber = extractNbr[0]);
        }
    }

    private getAddrComponent(place, componentTemplate): string {
        for (const component of place.address_components) {
            const addressType = component.types[0];
            if (componentTemplate[addressType]) {
                return component[componentTemplate[addressType]];
            }
        }
    }

    ngOnDestroy() {
        google.maps.event.clearInstanceListeners(this.geocomplete);
    }
}
