import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApplicationStatus } from '../models/Status';
import { MaintenanceService } from '../services/maintenance.service';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {
    constructor(private router: Router, private maintenanceService: MaintenanceService) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.maintenanceService.getStatus().pipe(
            switchMap((resp) => {
                if (resp.status === ApplicationStatus.Down) {
                    this.router.navigate(['/maintenance']);
                    return of(false);
                }
                return of(true);
            })
        );
    }
}
