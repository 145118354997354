import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.enableProdMode) {
    enableProdMode();
}

if (environment.production && environment.dynatrace) {
    // Add Dynatrace script to <head>
    const script = document.createElement('script');
    script.src = environment.dynatrace;
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
