<div
    #container
    class="ui-toast-message ui-shadow"
    [@messageState]="{
        value: 'visible',
        params: {
            showTransitionParams: showTransitionOptions,
            hideTransitionParams: hideTransitionOptions
        }
    }"
    [ngClass]="{
        'ui-toast-message-info': message.severity == 'info',
        'ui-toast-message-warn': message.severity == 'warn',
        'ui-toast-message-error': message.severity == 'error',
        'ui-toast-message-success': message.severity == 'success',
        banner: message.data && message.data.isBanner
    }"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
>
    <div class="ui-toast-message-content">
        <a
            tabindex="0"
            class="ui-toast-close-icon pi pi-times"
            (click)="onCloseIconClick($event)"
            (keydown.enter)="onCloseIconClick($event)"
            *ngIf="message.closable !== false"
        ></a>
        <ng-container *ngIf="!template">
            <span
                class="ui-toast-icon pi"
                [ngClass]="{
                    'pi-info-circle': message.severity == 'info',
                    'pi-exclamation-triangle': message.severity == 'warn',
                    'pi-times': message.severity == 'error',
                    'pi-check': message.severity == 'success'
                }"
            ></span>
            <div class="ui-toast-message-text-content">
                <div class="ui-toast-summary">{{ message.summary }}</div>
                <div class="ui-toast-detail">{{ message.detail }}</div>
            </div>
        </ng-container>
        <ng-container *ngTemplateOutlet="template; context: { $implicit: message }"></ng-container>
    </div>
</div>
