import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { HeaderService } from './services/header.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input() title: string;
    @Input() cancelText: string;
    @Input() changesText: string;
    @Input() saveText: string;
    @Input() tabs: MenuItem[];
    @Input() harvestLabel: string;

    @Output() harvest = new EventEmitter();

    showBtn = false;
    isSaving = false;
    headerSubscription: Subscription;

    constructor(private headerService: HeaderService) {}

    ngOnInit() {
        this.headerSubscription = this.headerService.showBtn$.subscribe((showBtn) => {
            this.isSaving = false;
            this.showBtn = showBtn;
        });
    }

    cancel() {
        this.isSaving = false;
        this.headerService.cancel();
    }

    save() {
        this.isSaving = true;
        this.headerService.save();
    }

    onHarvest() {
        this.harvest.emit();
    }

    ngOnDestroy(): void {
        if (this.headerSubscription) {
            this.headerSubscription.unsubscribe();
        }
    }
}
