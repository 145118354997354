import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-base-section',
    templateUrl: './base-section.component.html',
    styleUrls: ['./base-section.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BaseSectionComponent implements OnInit {
    @Input() isWrapping: string;
    @Input() noLeft = false;
    @Input() noRight = false;
    @Input() sectionClass: string;

    bgColor: string;
    sectionClasses: {
        'section--no-left': boolean;
        'section--no-right': boolean;
    };

    constructor() {}

    ngOnInit() {
        this.sectionClasses = {
            'section--no-left': this.noLeft,
            'section--no-right': this.noRight,
        };
        if (this.sectionClass) {
            this.sectionClass = `section--${this.sectionClass}`;
        }
    }
}
