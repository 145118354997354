import { Directive, Input, ViewContainerRef, TemplateRef, EmbeddedViewRef } from '@angular/core';

import { FeaturePermissionService } from '../services/feature-permission.service';

@Directive({
    selector: '[appFeaturePermission]',
})
export class FeaturePermissionDirective {
    private featureName: string;
    private viewRef: EmbeddedViewRef<any> | null;

    @Input()
    set appFeaturePermission(featureName: string) {
        this.featureName = featureName;
        this.viewRef = null;
        this.updateView();
    }

    constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>, private featurePermissionService: FeaturePermissionService) {}

    private updateView() {
        const condition = this.featurePermissionService.isAuthorized(this.featureName);

        if (condition && !this.viewRef) {
            this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
        } else if (!condition) {
            this.viewContainer.clear();
            this.viewRef = null;
        }
    }
}
