import { AppConfig } from './shared/models/AppConfig';
import { UserRole } from './shared/models/User';
import { version } from 'src/environments/version';
import { environment } from '@src/environments/environment';

const initAppConfig: AppConfig = {
    regex: {
        email:
            '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|' +
            '(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    },
    roles: [UserRole.CLINIC_STAFF, UserRole.CLINIC_ADMIN, UserRole.POMELO_ADMIN],
    featurePermission: {
        clinicSettings: {
            role: UserRole.POMELO_ADMIN,
        },
        clinicSettingsCallerId: {
            role: UserRole.POMELO_ADMIN,
        },
        createClinic: {
            role: UserRole.POMELO_ADMIN,
        },
        updateProductLang: {
            role: UserRole.POMELO_ADMIN,
        },
        magicseatArchiveQueue: {
            role: UserRole.CLINIC_ADMIN,
        },
        magicseatClinicInfoToggle: {
            role: UserRole.POMELO_ADMIN,
        },
        magicseatCreateQueue: {
            role: UserRole.CLINIC_ADMIN,
        },
        magicseatCreateWaitingRoom: {
            role: UserRole.CLINIC_ADMIN,
        },
        magicseatEditWaitingRoom: {
            role: UserRole.CLINIC_ADMIN,
        },
        magicseatMobile: {
            country: ['ca'],
        },
        magicseatPositionNotification: {
            country: ['ca'],
        },
        magicseatQueueOptions: {
            role: UserRole.CLINIC_ADMIN,
        },
        magicseatQuickAdd: {
            role: UserRole.POMELO_ADMIN,
        },
        magicseatSettings: {
            role: UserRole.CLINIC_ADMIN,
        },
        magicseatReasonVisit: {
            config: ['isReasonForVisitEnabled'],
        },
        magicseatResetQueue: {
            role: UserRole.CLINIC_ADMIN,
        },
        magicseatWaitListSettings: {
            country: ['ca'],
        },
        magicseatWaitList: {
            country: ['ca'],
            config: ['isUsingWaitList'],
        },
        userCreate: {
            role: UserRole.CLINIC_ADMIN,
        },
        styleGuide: {
            environment: ['dev'],
        },
        updateClinicAddress: {
            role: UserRole.CLINIC_ADMIN,
        },
    },
    supportedLanguages: [
        {
            key: 'en',
            label: 'English',
        },
    ],
    version,
    geographic: {
        country: {
            ca: {
                info: {
                    code: 'CA',
                    label: 'Canada',
                    currencyCode: 'CAD',
                },
                province: [
                    {
                        code: 'AB',
                        label: 'Alberta',
                    },
                    {
                        code: 'BC',
                        label: 'British Columbia',
                    },
                    {
                        code: 'MB',
                        label: 'Manitoba',
                    },
                    {
                        code: 'NB',
                        label: 'New Brunswick',
                    },
                    {
                        code: 'NL',
                        label: 'Newfoundland and Labrador',
                    },
                    {
                        code: 'NS',
                        label: 'Nova Scotia',
                    },
                    {
                        code: 'NT',
                        label: 'Northwest Territories',
                    },
                    {
                        code: 'NU',
                        label: 'Nunavut',
                    },
                    {
                        code: 'ON',
                        label: 'Ontario',
                    },
                    {
                        code: 'PE',
                        label: 'Prince Edward Island',
                    },
                    {
                        code: 'QC',
                        label: 'Quebec',
                    },
                    {
                        code: 'SK',
                        label: 'Saskatchewan',
                    },
                    {
                        code: 'YT',
                        label: 'Yukon',
                    },
                ],
                timezone: [
                    {
                        label: 'Vancouver',
                        code: 'America/Vancouver',
                        utc: 'UTC-7',
                        tz: 'PDT',
                    },
                    {
                        label: 'Edmonton',
                        code: 'America/Edmonton',
                        utc: 'UTC-6',
                        tz: 'MDT',
                    },
                    {
                        label: 'Regina',
                        code: 'America/Regina',
                        utc: 'UTC-6',
                        tz: 'RDT',
                    },
                    {
                        label: 'Winnipeg',
                        code: 'America/Winnipeg',
                        utc: 'UTC-5',
                        tz: 'CDT',
                    },
                    {
                        label: 'Montréal',
                        code: 'America/Montreal',
                        utc: 'UTC-4',
                        tz: 'EDT',
                    },
                    {
                        label: 'Toronto',
                        code: 'America/Toronto',
                        utc: 'UTC-4',
                        tz: 'EDT',
                    },
                    {
                        label: 'Moncton',
                        code: 'America/Moncton',
                        utc: 'UTC-3',
                        tz: 'ADT',
                    },
                    {
                        label: "St. John's",
                        code: 'America/St_Johns',
                        utc: 'UTC-2:30',
                        tz: 'NDT',
                    },
                    {
                        label: 'Miquelon',
                        code: 'America/Miquelon',
                        utc: 'UTC-2',
                        tz: 'NDT',
                    },
                ],
            },
            us: {
                info: {
                    code: 'US',
                    label: 'United States of America',
                    currencyCode: 'USD',
                },
                province: [
                    {
                        code: 'AL',
                        label: 'Alabama',
                    },
                    {
                        code: 'AK',
                        label: 'Alaska',
                    },
                    {
                        code: 'AZ',
                        label: 'Arizona',
                    },
                    {
                        code: 'AR',
                        label: 'Arkansas',
                    },
                    {
                        code: 'CA',
                        label: 'California',
                    },
                    {
                        code: 'CO',
                        label: 'Colorado',
                    },
                    {
                        code: 'CT',
                        label: 'Connecticut',
                    },
                    {
                        code: 'DE',
                        label: 'Delaware',
                    },
                    {
                        code: 'FL',
                        label: 'Florida',
                    },
                    {
                        code: 'GA',
                        label: 'Georgia',
                    },
                    {
                        code: 'HI',
                        label: 'Hawaii',
                    },
                    {
                        code: 'ID',
                        label: 'Idaho',
                    },
                    {
                        code: 'IL',
                        label: 'Illinois',
                    },
                    {
                        code: 'IN',
                        label: 'Indiana',
                    },
                    {
                        code: 'IA',
                        label: 'Iowa',
                    },
                    {
                        code: 'KS',
                        label: 'Kansas',
                    },
                    {
                        code: 'KY',
                        label: 'Kentucky',
                    },
                    {
                        code: 'LA',
                        label: 'Louisiana',
                    },
                    {
                        code: 'ME',
                        label: 'Maine',
                    },
                    {
                        code: 'MD',
                        label: 'Maryland',
                    },
                    {
                        code: 'MA',
                        label: 'Massachusetts',
                    },
                    {
                        code: 'MI',
                        label: 'Michigan',
                    },
                    {
                        code: 'MN',
                        label: 'Minnesota',
                    },
                    {
                        code: 'MS',
                        label: 'Mississippi',
                    },
                    {
                        code: 'MO',
                        label: 'Missouri',
                    },
                    {
                        code: 'MT',
                        label: 'Montana',
                    },
                    {
                        code: 'NE',
                        label: 'Nebraska',
                    },
                    {
                        code: 'NV',
                        label: 'Nevada',
                    },
                    {
                        code: 'NH',
                        label: 'New Hampshire',
                    },
                    {
                        code: 'NJ',
                        label: 'New Jersey',
                    },
                    {
                        code: 'NM',
                        label: 'New Mexico',
                    },
                    {
                        code: 'NY',
                        label: 'New York',
                    },
                    {
                        code: 'NC',
                        label: 'North Carolina',
                    },
                    {
                        code: 'ND',
                        label: 'North Dakota',
                    },
                    {
                        code: 'OH',
                        label: 'Ohio',
                    },
                    {
                        code: 'OK',
                        label: 'Oklahoma',
                    },
                    {
                        code: 'OR',
                        label: 'Oregon',
                    },
                    {
                        code: 'PA',
                        label: 'Pennsylvania',
                    },
                    {
                        code: 'RI',
                        label: 'Rhode Island',
                    },
                    {
                        code: 'SC',
                        label: 'South Carolina',
                    },
                    {
                        code: 'SD',
                        label: 'South Dakota',
                    },
                    {
                        code: 'TN',
                        label: 'Tennessee',
                    },
                    {
                        code: 'TX',
                        label: 'Texas',
                    },
                    {
                        code: 'UT',
                        label: 'Utah',
                    },
                    {
                        code: 'VT',
                        label: 'Vermont',
                    },
                    {
                        code: 'VA',
                        label: 'Virginia',
                    },
                    {
                        code: 'WA',
                        label: 'Washington',
                    },
                    {
                        code: 'WV',
                        label: 'West Virginia',
                    },
                    {
                        code: 'WI',
                        label: 'Wisconsin',
                    },
                    {
                        code: 'WY',
                        label: 'Wyoming',
                    },
                    {
                        code: 'AS',
                        label: 'American Samoa (outlying area)',
                    },
                    {
                        code: 'GU',
                        label: 'Guam (outlying area)',
                    },
                    {
                        code: 'MP',
                        label: 'Northern Mariana Islands (outlying area)',
                    },
                    {
                        code: 'PR',
                        label: 'Puerto Rico (outlying area)',
                    },
                    {
                        code: 'UM',
                        label: 'United States Minor Outlying Islands (outlying area)',
                    },
                    {
                        code: 'VI',
                        label: 'Virgin Islands, U.S. (outlying area)',
                    },
                    {
                        code: 'DC',
                        label: 'District of Columbia (district)',
                    },
                ],
                timezone: [
                    {
                        label: 'Honolulu',
                        code: 'Pacific/Honolulu',
                        utc: 'UTC-10',
                        tz: 'HST',
                    },
                    {
                        label: 'Anchorage',
                        code: 'America/Anchorage',
                        utc: 'UTC-8',
                        tz: 'AKDT',
                    },
                    {
                        label: 'Los Angeles',
                        code: 'America/Los_Angeles',
                        utc: 'UTC-7',
                        tz: 'PDT',
                    },
                    {
                        label: 'Phoenix',
                        code: 'America/Phoenix',
                        utc: 'UTC-7',
                        tz: 'MST',
                    },
                    {
                        label: 'Salt Lake City',
                        code: 'America/Boise',
                        utc: 'UTC-6',
                        tz: 'MDT',
                    },
                    {
                        label: 'Chicago',
                        code: 'America/Chicago',
                        utc: 'UTC-5',
                        tz: 'CDT',
                    },
                    {
                        label: 'New York',
                        code: 'America/New_York',
                        utc: 'UTC-4',
                        tz: 'EDT',
                    },
                ],
            },
        },
    },
};

if (environment.countryCode === 'ca') {
    initAppConfig.supportedLanguages.push({
        key: 'fr',
        label: 'Français',
    });
} else {
    initAppConfig.supportedLanguages.push({
        key: 'es',
        label: 'Español',
    });
}

export const appConfig: AppConfig = initAppConfig;
