import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Event as RouterEvent, NavigationEnd } from '@angular/router';
import { SideNavItem } from '../../models/SideNavItem';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { FeaturePermissionService } from 'src/app/shared/services/feature-permission.service';

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnChanges {
    @Input() items: SideNavItem[];

    settingsItems: SelectItem[] = [];
    settingsItemSelected: string;

    constructor(private router: Router, private featurePermissionService: FeaturePermissionService) {
        router.events.subscribe((event: RouterEvent): void => {
            if (event instanceof NavigationEnd && this.items) {
                this.settingsItemSelected = event.url;
            }
        });
    }

    ngOnInit() {}

    ngOnChanges(changes) {
        if (changes.items && changes.items.currentValue.length) {
            for (const item of this.items) {
                if (!item.featureName || this.featurePermissionService.isAuthorized(item.featureName)) {
                    this.settingsItems.push({
                        label: item.label,
                        value: item.link,
                    });
                }
            }
            this.settingsItemSelected = this.router.url;
        }
    }

    onSettingsItemsChange() {
        this.router.navigate([this.settingsItemSelected]);
    }
}
