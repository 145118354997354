import { OnDestroy, Input, TemplateRef, Output, ViewChild, ElementRef, OnInit, AfterContentInit, ContentChildren, QueryList, Component, EventEmitter } from '@angular/core';
import { trigger, transition, query, animateChild, AnimationEvent } from '@angular/animations';
import { PrimeTemplate } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { Subscription } from 'rxjs';
import { MessageService, Message } from 'primeng/api';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    animations: [trigger('toastAnimation', [transition(':enter, :leave', [query('@*', animateChild())])])],
})
export class ToastComponent implements OnInit, AfterContentInit, OnDestroy {
    @Input() key: string;

    @Input() autoZIndex = true;

    @Input() baseZIndex = 0;

    @Input() style: any;

    @Input() styleClass: string;

    @Input() position = 'top-right';

    @Input() modal: boolean;

    @Input() showTransitionOptions = '300ms ease-out';

    @Input() hideTransitionOptions = '250ms ease-in';

    @Output() close = new EventEmitter();

    @ViewChild('container') containerViewChild: ElementRef;

    @ContentChildren(PrimeTemplate) templates: QueryList<any>;

    messageSubscription: Subscription;

    clearSubscription: Subscription;

    messages: Message[];

    template: TemplateRef<any>;

    mask: HTMLDivElement;

    constructor(public messageService: MessageService) {}

    ngOnInit() {
        this.messageSubscription = this.messageService.messageObserver.subscribe((messages) => {
            if (messages) {
                if (messages instanceof Array) {
                    const filteredMessages = messages.filter((m) => this.key === m.key);
                    this.messages = this.messages ? [...this.messages, ...filteredMessages] : [...filteredMessages];
                } else if (this.key === messages.key) {
                    if (!messages.data || !messages.data.preventDuplicates || !this.messages || !this.messages.some((x) => x.summary === messages.summary)) {
                        this.messages = this.messages ? [...this.messages, ...[messages]] : [messages];
                    }
                }

                if (this.modal && this.messages && this.messages.length) {
                    this.enableModality();
                }
            }
        });

        this.clearSubscription = this.messageService.clearObserver.subscribe((key) => {
            if (key) {
                if (this.key === key) {
                    this.messages = null;
                }
            } else {
                this.messages = null;
            }

            if (this.modal) {
                this.disableModality();
            }
        });
    }

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'message':
                    this.template = item.template;
                    break;

                default:
                    this.template = item.template;
                    break;
            }
        });
    }

    onMessageClose(event) {
        this.messages.splice(event.index, 1);

        if (this.messages.length === 0) {
            this.disableModality();
        }

        this.close.emit({
            message: event.message,
        });
    }

    enableModality() {
        if (!this.mask) {
            this.mask = document.createElement('div');
            this.mask.style.zIndex = String(parseInt(this.containerViewChild.nativeElement.style.zIndex, 10) - 1);
            const maskStyleClass = 'ui-widget-overlay ui-dialog-mask';
            DomHandler.addMultipleClasses(this.mask, maskStyleClass);
            document.body.appendChild(this.mask);
        }
    }

    disableModality() {
        if (this.mask) {
            document.body.removeChild(this.mask);
            this.mask = null;
        }
    }

    onAnimationStart(event: AnimationEvent) {
        if (event.fromState === 'void' && this.autoZIndex) {
            this.containerViewChild.nativeElement.style.zIndex = String(this.baseZIndex + ++DomHandler.zindex);
        }
    }

    ngOnDestroy() {
        if (this.messageSubscription) {
            this.messageSubscription.unsubscribe();
        }

        if (this.clearSubscription) {
            this.clearSubscription.unsubscribe();
        }

        this.disableModality();
    }
}
