import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-clinicless',
    templateUrl: './clinicless.component.html',
    styleUrls: ['./clinicless.component.scss'],
})
export class CliniclessComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
