import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModule } from 'primeng/sidebar';

import { DrawerComponent } from './drawer.component';
import { DrawerWrapperComponent } from './drawer-wrapper.component';
import { DrawerService } from './drawer.service';

@NgModule({
    declarations: [DrawerComponent, DrawerWrapperComponent],
    providers: [DrawerService],
    imports: [CommonModule, SidebarModule],
})
export class DrawerModule {}
