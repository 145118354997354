<div class="ancnav-overlay"></div>
<div class="ancnav ancnav-module" [attr.data-ancnav-opened]="opened">
    <div class="ancnav__top">
        <span class="subtitle--two">In this page</span>
        <div class="ancnav__grip" (click)="onGripClick()">
            <span class="icon icon-log-out icon--sm ancnav__icon-open"></span>
            <span class="icon icon-close icon--sm ancnav__icon-close"></span>
        </div>
    </div>
    <div class="ancnav__bottom">
        <ul class="ancnav__list">
            <li class="ancnav__item">
                <a (click)="onTopAnchorClick($event)" class="ancnav__link">Top</a>
            </li>
            <li class="ancnav__item" *ngFor="let item of navigation">
                <a [href]="item.anchor" (click)="onAnchorClick($event)" class="ancnav__link" #ancnavLinks>{{ item.text }}</a>
            </li>
        </ul>
    </div>
</div>
