<app-base-section [sectionClass]="'header'">
    <div class="top-bar">
        <img
            *ngIf="!hasNotifBanner"
            class="top-bar__logo"
            src="{{ 'assets/images/brand/' + currentLanguage + '/logo.' + fileExt }}"
            alt="Pomelo by TELUS Health"
            (click)="onMainPageRedirect()"
        />

        <app-notification-banner class="top-bar__banner" [startDate]="'2022-04-18'" [endDate]="'2022-05-24'" (hasNotifBanner)="onInfoBannerShow($event)"></app-notification-banner>

        <div class="top-bar__dropdown" [ngClass]="{ 'top-bar__dropdown--right': clinicItems.length <= 1 }">
            <app-dropdown
                *ngIf="clinicItems.length > 1"
                #dd
                class="dropdown dropdown--full"
                [options]="clinicItems"
                [(ngModel)]="selectedClinicId"
                (onHide)="onCurrentClinicChange()"
                (onFocus)="onDropDownFocus(dd)"
                [placeholder]="'app.top_bar.clinic_dd_placeholder' | translate"
                [filter]="true"
                [resetFilterOnHide]="true"
                [scrollHeight]="'410px'"
                [emptyFilterMessage]="'app.top_bar.no_results' | translate"
            ></app-dropdown>

            <span class="clinic-name" *ngIf="clinicItems.length === 1">{{ currentClinicName }}</span>
            <app-dropdown-menu class="top-bar__dd-menu"></app-dropdown-menu>
        </div>
    </div>
</app-base-section>
