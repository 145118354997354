import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    handleError(err: Error): void {
        if (err.stack) {
            console.error(err.stack.toString());
        } else {
            console.error(err.message);
        }

        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (err && chunkFailedMessage.test(err.message)) {
            window.location.reload();
        }
    }
}
