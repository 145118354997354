import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { UserCreate } from '../models/User';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private headers: HttpHeaders;

    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders().set('Content-Type', 'application/json');
    }

    create(userData: UserCreate): Observable<UserCreate> {
        return this.http.post<UserCreate>(`${environment.walkinapi}walk-in/users`, userData, { headers: this.headers });
    }
}
