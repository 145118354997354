import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'language',
})
export class LanguagePipe implements PipeTransform {
    transform(value: string): any {
        if (value.toLowerCase() === 'english') {
            return 'EN';
        } else {
            return 'FR';
        }
    }
}
