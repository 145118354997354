import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { of, Observable, BehaviorSubject, ReplaySubject, from, defer, Subject, iif } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { UserRole } from '../models/User';
import { Auth0Client, GetTokenSilentlyOptions, LogoutOptions, RedirectLoginOptions, RedirectLoginResult, User } from '@auth0/auth0-spa-js';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
    private currentUser: User;
    private webAuth = new Auth0Client({
        domain: environment.auth.domain,
        client_id: environment.auth.client_id,
        responseType: 'token',
        audience: environment.auth.audience,
        redirect_uri: window.location.origin,
        useRefreshTokens: true,
    });
    private isLoadingSubject$ = new BehaviorSubject(true);
    private isAuthenticatedSubject$ = new BehaviorSubject(false);
    private errorSubject$ = new ReplaySubject<Error>(1);

    // https://stackoverflow.com/a/41177163
    private ngUnsubscribe$ = new Subject();

    readonly isLoading$ = this.isLoadingSubject$.asObservable();
    readonly isAuthenticated$ = this.isLoading$.pipe(
        filter((loading) => !loading),
        distinctUntilChanged(),
        concatMap(() => this.isAuthenticatedSubject$)
    );
    readonly user$ = this.isAuthenticated$.pipe(
        filter((authenticated) => authenticated),
        distinctUntilChanged(),
        concatMap(() => this.webAuth.getUser()),
        map((user: any) => {
            this.currentUser = user;

            this.currentUser.app_metadata = user[`https://pomelo.health/app_metadata`];
            this.currentUser.user_metadata = user[`https://pomelo.health/user_metadata`];
            this.currentUser.user_roles = user[`https://pomelo.health/user_roles`];

            return this.currentUser;
        })
    );

    constructor(private router: Router) {
        const checkSessionOrCallback$ = (isCallback: boolean) =>
            iif(
                () => isCallback,
                this.handleRedirectCallback(),
                defer(() => this.webAuth.checkSession())
            );

        this.shouldHandleCallback()
            .pipe(
                switchMap((isCallback) =>
                    checkSessionOrCallback$(isCallback).pipe(
                        catchError((error) => {
                            this.errorSubject$.next(error);
                            this.router.navigateByUrl('/');
                            return of(undefined);
                        })
                    )
                ),
                concatMap(() => this.webAuth.isAuthenticated()),
                tap((authenticated) => {
                    this.isAuthenticatedSubject$.next(authenticated);
                    this.isLoadingSubject$.next(false);
                }),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe();
    }

    loginWithRedirect(options?: RedirectLoginOptions): Observable<void> {
        return from(this.webAuth.loginWithRedirect(options));
    }

    getUserLogged(): User {
        return this.currentUser;
    }

    public setUserLogged(user: User) {
        this.currentUser = user;
    }

    public getUserRole(): string {
        return this.currentUser.user_roles[0];
    }

    public isCliniclessUser(): boolean {
        return this.currentUser.user_metadata.clinicIds && this.currentUser.user_metadata.clinicIds[0] ? false : true;
    }

    getAccessTokenSilently(options?: GetTokenSilentlyOptions): Observable<string> {
        return of(this.webAuth).pipe(concatMap((client) => client.getTokenSilently(options)));
    }

    logout(options?: LogoutOptions) {
        localStorage.removeItem('userClinic');

        this.webAuth.logout(options);

        if (options && options.localOnly) {
            this.isAuthenticatedSubject$.next(false);
        }
    }

    ngOnDestroy(): void {
        // https://stackoverflow.com/a/41177163
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    private shouldHandleCallback(): Observable<boolean> {
        const params = new URLSearchParams(window.location.search);
        return of(params.has('code') || params.has('error') || params.has('state'));
    }

    private handleRedirectCallback(): Observable<RedirectLoginResult> {
        return defer(() => this.webAuth.handleRedirectCallback()).pipe(
            tap((result) => {
                const target = result && result.appState && result.appState.target ? result.appState.target : '/';
                this.router.navigateByUrl(target);
            })
        );
    }
}
