<div
    #container
    [ngClass]="{
        'ui-dropdown ui-multiselect ui-widget ui-state-default ui-corner-all': true,
        'ui-dropdown-open ui-multiselect-open': overlayVisible,
        'ui-state-focus': focus,
        'ui-state-disabled': disabled
    }"
    [ngStyle]="style"
    [class]="styleClass"
    (click)="onMouseclick($event, in)"
>
    <div class="ui-helper-hidden-accessible">
        <input
            #in
            type="text"
            readonly="readonly"
            [attr.id]="inputId"
            [attr.name]="name"
            (focus)="onInputFocus($event)"
            (blur)="onInputBlur($event)"
            [disabled]="disabled"
            [attr.tabindex]="tabindex"
            (keydown)="onKeydown($event)"
        />
    </div>

    <div class="ui-multiselect-label-container">
        <span class="ui-dropdown-label ui-multiselect-label ui-corner-all">
            <ng-container *ngIf="!selectedItemsTemplate">{{ valuesAsString }}</ng-container>
            <ng-container *ngTemplateOutlet="selectedItemsTemplate; context: { $implicit: value }"></ng-container>
        </span>
    </div>

    <div
        [ngClass]="{
            'ui-dropdown-trigger ui-multiselect-trigger ui-state-default ui-corner-right': true
        }"
    >
        <span class="ui-dropdown-trigger-icon ui-multiselect-trigger-icon ui-clickable" [ngClass]="dropdownIcon"></span>
    </div>

    <div
        *ngIf="overlayVisible"
        [ngClass]="['ui-multiselect-panel ui-widget ui-widget-content ui-corner-all ui-shadow']"
        [@overlayAnimation]="{
            value: 'visible',
            params: {
                showTransitionParams: showTransitionOptions,
                hideTransitionParams: hideTransitionOptions
            }
        }"
        (@overlayAnimation.start)="onOverlayAnimationStart($event)"
        [ngStyle]="panelStyle"
        [class]="panelStyleClass"
        (click)="panelClick = true"
        (keydown)="onKeydown($event)"
    >
        <div
            class="ui-widget-header ui-corner-all ui-multiselect-header ui-helper-clearfix"
            [ngClass]="{ 'ui-multiselect-header-no-toggleall': !showToggleAll }"
            *ngIf="showHeader"
        >
            <ng-content select="p-header"></ng-content>

            <div class="ui-dropdown-filter-container" *ngIf="filter">
                <input
                    #filterInput
                    type="text"
                    role="textbox"
                    [value]="filterValue || ''"
                    (input)="onFilter()"
                    class="ui-inputtext ui-widget ui-state-default ui-corner-all"
                    [attr.placeholder]="filterPlaceHolder"
                    [attr.aria-label]="ariaFilterLabel"
                />
                <span class="ui-dropdown-filter-icon pi pi-search"></span>
            </div>

            <div class="selection-summary-container" *ngIf="showToggleAll">
                <div class="ui-chkbox ui-widget" *ngIf="showToggleAll && !selectionLimit">
                    <div class="ui-helper-hidden-accessible">
                        <input
                            type="checkbox"
                            readonly="readonly"
                            [checked]="isAllChecked()"
                            (focus)="onHeaderCheckboxFocus()"
                            (blur)="onHeaderCheckboxBlur()"
                            (keydown.space)="toggleAll($event)"
                        />
                    </div>
                    <div
                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"
                        [ngClass]="{
                            'ui-state-active': isAllChecked(),
                            'ui-state-focus': headerCheckboxFocus
                        }"
                        (click)="toggleAll($event)"
                    >
                        <span class="ui-chkbox-icon ui-clickable" [ngClass]="{ 'pi pi-check': isAllChecked() }"></span>
                    </div>
                    <label class="chkbox-label" (click)="toggleAll($event)">{{ selectAllLabel }}</label>
                </div>

                <p *ngIf="value && value.length > 0" class="selected-count">
                    <span>{{ value.length }}</span>
                    {{ selectCountLabel }}
                </p>
            </div>

            <a class="ui-multiselect-close ui-corner-all" tabindex="0" (click)="close($event)" (keydown.enter)="close($event)">
                <span class="pi pi-times"></span>
            </a>
        </div>

        <div class="ui-multiselect-items-wrapper" [style.max-height]="virtualScroll ? 'auto' : scrollHeight || 'auto'">
            <ul class="ui-multiselect-items ui-multiselect-list ui-widget-content ui-widget ui-corner-all ui-helper-reset">
                <ng-container *ngIf="!virtualScroll; else virtualScrollList">
                    <ng-template ngFor let-option let-i="index" [ngForOf]="options">
                        <app-multi-select-item
                            [option]="option"
                            [selected]="isSelected(option.value)"
                            (onClick)="onOptionClick($event)"
                            (onKeydown)="onOptionKeydown($event)"
                            [maxSelectionLimitReached]="maxSelectionLimitReached"
                            [visible]="isItemVisible(option)"
                            [template]="itemTemplate"
                        ></app-multi-select-item>
                    </ng-template>
                </ng-container>

                <ng-template #virtualScrollList>
                    <cdk-virtual-scroll-viewport
                        #viewport
                        [ngStyle]="{ height: scrollHeight }"
                        [itemSize]="itemSize"
                        *ngIf="virtualScroll && visibleOptions && visibleOptions.length"
                    >
                        <ng-container *cdkVirtualFor="let option of visibleOptions; let i = index; let c = count; let f = first; let l = last; let e = even; let o = odd">
                            <app-multi-select-item
                                [option]="option"
                                [selected]="isSelected(option.value)"
                                (onClick)="onOptionClick($event)"
                                (onKeydown)="onOptionKeydown($event)"
                                [maxSelectionLimitReached]="maxSelectionLimitReached"
                                [visible]="isItemVisible(option)"
                                [template]="itemTemplate"
                                [itemSize]="itemSize"
                            ></app-multi-select-item>
                        </ng-container>
                    </cdk-virtual-scroll-viewport>
                </ng-template>

                <li *ngIf="filter && visibleOptions && visibleOptions.length === 0" class="ui-multiselect-empty-message">
                    {{ emptyFilterMessage }}
                </li>
            </ul>
        </div>

        <div class="ui-multiselect-footer ui-widget-content" *ngIf="footerFacet">
            <ng-content select="p-footer"></ng-content>
        </div>
    </div>
</div>
