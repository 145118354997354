import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './calendar.component';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'primeng/api';

@NgModule({
    imports: [CommonModule, ButtonModule, SharedModule],
    exports: [CalendarComponent, ButtonModule, SharedModule],
    declarations: [CalendarComponent],
})
export class CalendarModule {}
