import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import moment from 'moment';
import 'moment-duration-format';

import { ToolsService } from 'src/app/shared/services/tools.service';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    public lang$: Subject<string>;
    private lang: string;

    constructor(public toolsService: ToolsService) {
        this.lang$ = new Subject<string>();
    }

    getLang(): string {
        return this.lang;
    }

    setLang(lang: string) {
        this.lang = lang;
        this.updateLocale();
        moment.locale(this.lang);
        localStorage.setItem('cmqlang', lang);
        this.lang$.next(lang);
        this.toolsService.setAttribute(document.querySelector('html'), 'lang', lang);
    }

    private updateLocale() {
        if (this.lang === 'fr') {
            moment.updateLocale('fr', {
                weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                weekdaysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
            });
        }
        if (this.lang === 'es') {
            moment.updateLocale('es', {
                weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mier', 'Jue', 'Vier', 'Sáb'],
                weekdaysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
            });
        }
    }
}
