import { Component, OnInit, Input } from '@angular/core';

import { LanguageService } from 'src/app/shared/services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
    @Input() key: string;
    @Input() isFullScreen: boolean;
    @Input() hasText?: boolean;

    constructor(private languageService: LanguageService, private readonly translate: TranslateService) {}

    ngOnInit() {
        this.translate.use(this.languageService.getLang());
    }
}
