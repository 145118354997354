import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';

import { SelectItem, MessageService } from 'primeng/api';
import { noWhiteSpaceValidator } from 'src/app/shared/directives/no-white-space.directive';

import { appConfig } from 'src/app/app.config';
import { UserRole, UserCreate } from 'src/app/shared/models/User';
import { Language } from 'src/app/shared/models/AppConfig';
import { LanguageService } from 'src/app/shared/services/language.service';
import { ClinicService } from 'src/app/shared/services/clinic.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Clinic } from '../../models/Clinic';

@Component({
    selector: 'app-user-create',
    templateUrl: './user-create.component.html',
    styleUrls: ['./user-create.component.scss'],
})
export class UserCreateComponent implements OnInit, OnDestroy {
    @Output() save = new EventEmitter();
    @Output() cancel = new EventEmitter();

    userCreateForm: FormGroup;
    clinicItems: SelectItem[] = [];
    userRoles: SelectItem[] = [];
    supportedLanguages: Language[];
    currentLang: Language;
    isSendingUserCreateForm = false;
    multiSelectCount = 0;
    clinicSelectedLabel = {
        '=1': 'app.user_create.form.clinic_selected',
        other: 'app.user_create.form.clinic_selected_plural',
    };

    private errorMessages: any;
    private langSubscription: Subscription;
    private clinicSubscription: Subscription;
    private createUserSubscription: Subscription;

    constructor(
        private readonly translate: TranslateService,
        private languageService: LanguageService,
        private authService: AuthService,
        private userService: UserService,
        private messageService: MessageService,
        private clinicService: ClinicService
    ) {}

    ngOnInit() {
        this.supportedLanguages = appConfig.supportedLanguages;

        this.currentLang = this.supportedLanguages.find((lang) => {
            return lang.key === this.languageService.getLang();
        });

        this.langSubscription = this.translate
            .get([
                'app.user_create.form.role.clinic_admin',
                'app.user_create.form.role.pomelo_admin',
                'app.user_create.form.role.clinic_staff',
                'app.user_create.form.error_generic',
                'app.user_create.form.user_exits',
                'app.user_create.form.invalid_password',
            ])
            .subscribe((translations: string[]) => {
                this.errorMessages = {
                    generic: translations['app.user_create.form.error_generic'],
                    user_exists: translations['app.user_create.form.user_exits'],
                    invalid_password: translations['app.user_create.form.invalid_password'],
                };

                let roles = [];

                for (const role in UserRole) {
                    roles.push(role);
                }

                const indexFound = roles.findIndex((role) => this.authService.getUserRole() === role);

                roles = roles.slice(indexFound);

                this.userRoles = roles.map((role: string) => {
                    return {
                        value: role,
                        label: translations[`app.user_create.form.role.${role.toLowerCase()}`],
                    };
                });
            });

        this.initClinicMultiselect();

        this.userCreateForm = new FormGroup({
            'user-firstname': new FormControl('', {
                validators: [Validators.required, noWhiteSpaceValidator()],
            }),
            'user-lastname': new FormControl('', {
                validators: [Validators.required, noWhiteSpaceValidator()],
            }),
            'user-email': new FormControl('', {
                validators: [Validators.email, Validators.required, Validators.pattern(appConfig.regex.email), noWhiteSpaceValidator()],
            }),
            'user-pass': new FormControl('', {
                validators: Validators.required,
            }),
            'select-lang': new FormControl(this.currentLang.key, {
                validators: Validators.required,
            }),
            'select-clinics': new FormControl('', {
                validators: Validators.required,
            }),
            'select-role': new FormControl('', {
                validators: Validators.required,
            }),
        });
    }

    onSelectedClinicChange(el: any): void {
        this.multiSelectCount = el.value.length;
    }

    onSelectedRole(): void {
        const selectedClinic = this.userCreateForm.get('select-clinics').value;

        if (this.userCreateForm.get('select-role').value === UserRole.POMELO_ADMIN) {
            this.userCreateForm.setControl('select-clinics', new FormControl(selectedClinic, { validators: [] }));
        } else {
            this.userCreateForm.setControl('select-clinics', new FormControl(selectedClinic, { validators: Validators.required }));
        }

        this.userCreateForm.get('select-clinics').updateValueAndValidity();
    }

    onSubmit() {
        if (this.userCreateForm.valid) {
            this.isSendingUserCreateForm = true;

            this.createUserSubscription = this.userService.create(this.createUser()).subscribe(
                () => {
                    this.isSendingUserCreateForm = false;
                    this.save.emit();
                },
                (err) => {
                    let errMsg = this.errorMessages.generic;

                    if (err.status === 400) {
                        errMsg = this.errorMessages[err.error.code];
                    }

                    this.messageService.add({
                        key: 'alert-top-center',
                        severity: 'error',
                        sticky: true,
                        closable: true,
                        summary: errMsg,
                    });

                    this.isSendingUserCreateForm = false;
                }
            );
        }
    }

    onCancel() {
        this.cancel.emit();
    }

    private createUser(): UserCreate {
        return {
            firstName: this.userCreateForm.get('user-firstname').value,
            lastName: this.userCreateForm.get('user-lastname').value,
            email: this.userCreateForm.get('user-email').value,
            password: this.userCreateForm.get('user-pass').value,
            role: this.userCreateForm.get('select-role').value,
            userMetadata: {
                language: this.userCreateForm.get('select-lang').value,
                clinicIds: this.userCreateForm.get('select-clinics').value ? this.userCreateForm.get('select-clinics').value : [],
            },
        };
    }

    private initClinicMultiselect() {
        const currentUser = this.authService.getUserLogged();

        if (this.authService.getUserRole() === UserRole.POMELO_ADMIN) {
            this.clinicService.getAllClinic().subscribe((clinics) => {
                this.clinicItems = clinics
                    .sort((a, b) => {
                        return parseInt(a.id, 10) - parseInt(b.id, 10);
                    })
                    .map((clinic) => {
                        const label = clinic.contactInformation ? `${clinic.contactInformation.name.en} (${clinic.id})` : null;
                        return {
                            label,
                            value: clinic.id,
                        };
                    });
            });
        } else {
            const clinics = currentUser.user_metadata.clinicIds.map((clinicId) => {
                return this.clinicService.getClinicById(clinicId.toString());
            });

            forkJoin(clinics).subscribe((results: Clinic[]) => {
                this.clinicItems = results
                    .map((clinic) => {
                        return {
                            label: clinic.contactInformation ? clinic.contactInformation.name.en : null,
                            value: clinic.id,
                        };
                    })
                    .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
            });
        }
    }

    ngOnDestroy() {
        if (this.langSubscription) {
            this.langSubscription.unsubscribe();
        }

        if (this.clinicSubscription) {
            this.clinicSubscription.unsubscribe();
        }

        if (this.createUserSubscription) {
            this.createUserSubscription.unsubscribe();
        }
    }
}
