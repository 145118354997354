import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { HttpConfigInterceptor } from './shared/interceptors/http-config.interceptor';
import { TopBarComponent } from './layouts/top-bar/top-bar.component';
import { DropdownMenuComponent } from './layouts/dropdown-menu/dropdown-menu.component';
import { SharedModule } from './shared/shared.module';
import { ToastModule } from './shared/modules/toast/toast.module';
import { DummyComponent } from './layouts/dummy/dummy.component';
import { DropdownModule } from './shared/modules/dropdown/dropdown.module';
import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/accordion';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CliniclessComponent } from './modules/clinicless/clinicless.component';
import { GlobalErrorHandler } from './shared/interceptors/global-error.handler';
import { SafeURLPipe } from './shared/pipes/safe-url.pipe';
import { MaintenanceComponent } from './shared/components/maintenance/maintenance.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/app/', '.json');
}

@NgModule({
    declarations: [AppComponent, ContentLayoutComponent, TopBarComponent, DropdownMenuComponent, DummyComponent, CliniclessComponent, MaintenanceComponent, SafeURLPipe],
    imports: [
        // angular
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            isolate: true,
        }),

        // app
        AppRoutingModule,
        SharedModule,
        ToastModule,

        // primeng
        DialogModule,
        DropdownModule,
        AccordionModule,
        OverlayPanelModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
        registerLocaleData(localeFr, 'fr');
        registerLocaleData(localeEs, 'es');
    }
}
