import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageService } from 'primeng/api';

import { ToastComponent } from './toast.component';
import { ToastItemComponent } from './toast-item/toast-item/toast-item.component';

@NgModule({
    declarations: [ToastComponent, ToastItemComponent],
    providers: [MessageService],
    imports: [CommonModule],
    exports: [ToastComponent, ToastItemComponent],
})
export class ToastModule {}
