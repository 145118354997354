<p-sidebar
    #drawer
    [(visible)]="isActive"
    [position]="position"
    [showCloseIcon]="true"
    [styleClass]="style"
    [ngClass]="options.class"
    [dismissible]="options.dismissible"
    [blockScroll]="true"
    (onHide)="onHide()"
>
    <ng-template #element></ng-template>
</p-sidebar>
