import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-alert-box',
    templateUrl: './alert-box.component.html',
    styleUrls: ['./alert-box.component.scss'],
})
export class AlertBoxComponent implements OnInit {
    @Input() type: string; // info | warning | error
    @Input() message: string;
    @Input() content: boolean;

    constructor() {}

    ngOnInit() {}
}
