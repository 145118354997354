<li
    class="ui-multiselect-item ui-corner-all"
    (click)="onOptionClick($event)"
    (keydown)="onOptionKeydown($event)"
    [attr.aria-label]="option.label"
    [style.display]="visible ? 'block' : 'none'"
    [attr.tabindex]="option.disabled ? null : '0'"
    [ngStyle]="{ height: itemSize + 'px' }"
    [ngClass]="{
        'ui-state-highlight': selected,
        'ui-state-disabled': option.disabled || (maxSelectionLimitReached && !selected)
    }"
>
    <div class="ui-chkbox ui-widget">
        <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default" [ngClass]="{ 'ui-state-active': selected }">
            <span class="ui-chkbox-icon ui-clickable" [ngClass]="{ 'pi pi-check': selected }"></span>
        </div>
    </div>
    <span *ngIf="!template" [innerHTML]="option.label"></span>
    <ng-container *ngTemplateOutlet="template; context: { $implicit: option }"></ng-container>
</li>
