import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { User } from '@auth0/auth0-spa-js';
import { AuthService } from '../../shared/services/auth.service';
import { DrawerService } from 'src/app/shared/modules/drawer/drawer.service';
import { UserCreateComponent } from 'src/app/shared/components/user-create/user-create.component';
import { ClinicFormComponent } from 'src/app/shared/components/clinic/clinic-form.component';
import { ClinicService } from 'src/app/shared/services/clinic.service';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
    selector: 'app-dropdown-menu',
    templateUrl: './dropdown-menu.component.html',
    styleUrls: ['./dropdown-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DropdownMenuComponent implements OnInit, OnDestroy {
    onlyMagicSeatIsEnabled: boolean;
    hasSelectedClinic: boolean;
    user: User;
    isOpen = false;

    private configurationSubscription: Subscription;

    constructor(private authService: AuthService, private clinicService: ClinicService, private drawerService: DrawerService, private languageService: LanguageService) {}

    ngOnInit() {
        this.user = this.authService.getUserLogged();

        this.hasSelectedClinic = !!this.clinicService.getCurrentClinic();

        this.configurationSubscription = this.clinicService.currentClinic$.subscribe((_) => {
            this.hasSelectedClinic = !!this.clinicService.getCurrentClinic();
        });
    }

    onToogleMenu() {
        this.isOpen = !this.isOpen;
    }

    logout() {
        this.authService.logout({ returnTo: window.location.href });
    }

    createUserForm() {
        const component = this.drawerService.open(UserCreateComponent);

        component.save.subscribe(() => {
            this.drawerService.close();
        });

        component.cancel.subscribe(() => {
            this.drawerService.close();
        });
    }

    showClinicForm() {
        const component = this.drawerService.open(ClinicFormComponent);

        component.save.subscribe(() => {
            this.drawerService.close();
        });

        component.cancel.subscribe(() => {
            this.drawerService.close();
        });
    }

    ngOnDestroy() {
        if (this.configurationSubscription) {
            this.configurationSubscription.unsubscribe();
        }
    }
}
