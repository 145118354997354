import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class ToolsService {
    private renderer: Renderer2;
    private html = this.document.documentElement;
    private body = this.document.body;

    constructor(@Inject(DOCUMENT) public document: Document, private rendererFactory: RendererFactory2) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    setLangAttr(lang: string): void {
        this.document.documentElement.lang = lang;
    }

    addClass(el: any, cls: string): void {
        this.renderer.addClass(el, cls);
    }

    removeClass(el: any, cls: string): void {
        this.renderer.removeClass(el, cls);
    }

    addBodyClass(cls: string): void {
        this.addClass(this.body, cls);
    }

    removeBodyClass(cls: string): void {
        this.removeClass(this.body, cls);
    }

    toggleClass(el: any, cls: string): void {
        if (el.classList.contains(cls)) {
            this.removeClass(el, cls);
        } else {
            this.addClass(el, cls);
        }
    }

    setAttribute(el: any, attr: string, val: string): void {
        this.renderer.setAttribute(el, attr, val);
    }

    setBodyAttribute(attr: string, val: string): void {
        this.setAttribute(this.body, attr, val);
    }

    setTheme(themeKey: string, uiKey: string = 'light'): void {
        this.setBodyAttribute('data-theme', themeKey);
        this.setBodyAttribute('data-ui', uiKey);
    }

    bpIsBiggerThan(width: number) {
        return this.body.offsetWidth > width;
    }

    bpIsLowerOrEqualThan(width: number) {
        return this.body.offsetWidth <= width;
    }

    preventSiteScroll() {
        this.addBodyClass('prevent-scroll');
        this.addClass(this.html, 'prevent-scroll');
    }

    allowSiteScroll() {
        this.removeBodyClass('prevent-scroll');
        this.removeClass(this.html, 'prevent-scroll');
    }

    isMobile() {
        const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    scrollIntoView(el: Element | string, block = 'start') {
        if (typeof el === 'string') {
            el = this.document.querySelector(el);
        }
        if (el) {
            el.scrollIntoView({
                behavior: 'smooth',
                block: block as ScrollLogicalPosition,
            });
        }
    }

    rgbToHex(rgb) {
        // Choose correct separator
        const sep = rgb.indexOf(',') > -1 ? ',' : ' ';
        // Turn "rgb(r,g,b)" into [r,g,b]
        rgb = rgb.substr(4).split(')')[0].split(sep);

        let r = (+rgb[0]).toString(16);
        let g = (+rgb[1]).toString(16);
        let b = (+rgb[2]).toString(16);

        if (r.length === 1) {
            r = '0' + r;
        }
        if (g.length === 1) {
            g = '0' + g;
        }
        if (b.length === 1) {
            b = '0' + b;
        }

        return '#' + r + g + b;
    }
}
