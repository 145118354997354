import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpXsrfTokenExtractor } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private tokenExtractor: HttpXsrfTokenExtractor) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.getAccessTokenSilently().pipe(
            switchMap((token) => {
                if (request.url.includes(environment.walkinapi)) {
                    if (!request.headers.get('Authorization') && token) {
                        request = request.clone({
                            headers: request.headers.set('Authorization', 'Bearer ' + token),
                        });
                    }

                    const headerName = 'X-XSRF-TOKEN';
                    const csrftoken = this.tokenExtractor.getToken() as string;

                    if (csrftoken && !request.headers.has(headerName)) {
                        request = request.clone({ headers: request.headers.set(headerName, csrftoken) });
                    }
                }

                return next.handle(request).pipe(
                    catchError((err) => {
                        if (err.status === 401 || err.status === 403) {
                            this.authService.logout({ returnTo: window.location.href });
                        }
                        return throwError(err);
                    })
                );
            })
        );
    }
}
