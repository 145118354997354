import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import * as momentTimezone from 'moment-timezone';

import { environment } from 'src/environments/environment';
import { appConfig } from 'src/app/app.config';
import { Address, ContactInformation, Clinic } from '../models/Clinic';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ClinicService {
    currentClinic$: Subject<Clinic>;
    createClinic$: Subject<Clinic>;

    private url = 'walk-in/clinics';
    private currentClinic: Clinic;

    constructor(private http: HttpClient) {
        this.url = environment.walkinapi.concat(this.url);
        this.currentClinic$ = new Subject<Clinic>();
        this.createClinic$ = new Subject<Clinic>();
    }

    getAllClinic(): Observable<Clinic[]> {
        return this.http.get<{ clinics: Clinic[] }>(`${this.url}`).pipe(map((res) => res.clinics));
    }

    getClinicById(id: string): Observable<Clinic> {
        return this.http.get<Clinic>(`${this.url}/${id}`);
    }

    create(clinic: { timezone: string; languages: string[]; contactInformation: ContactInformation }): Observable<Clinic> {
        return this.http.post<Clinic>(this.url, clinic).pipe(
            tap((c) => {
                this.createClinic$.next(c);
                this.setCurrentClinic(c);
            })
        );
    }

    getClinicId(): string {
        return this.currentClinic.id;
    }

    getCurrentClinic(): Clinic {
        return this.currentClinic;
    }

    getClinicAddress(): Address {
        return this.currentClinic.contactInformation.address;
    }

    getClinicFormattedAddress(): string {
        if (this.currentClinic.contactInformation.address.formatted) {
            return this.currentClinic.contactInformation.address.formatted;
        } else {
            const officeNumber = this.currentClinic.contactInformation.address.officeNumber ? `${this.currentClinic.contactInformation.address.officeNumber}, ` : '';
            return (
                this.currentClinic.contactInformation.address.streetNumber +
                this.currentClinic.contactInformation.address.streetNumber +
                ', ' +
                officeNumber +
                this.currentClinic.contactInformation.address.city +
                ', ' +
                this.currentClinic.contactInformation.address.province +
                ', ' +
                this.currentClinic.contactInformation.address.postalCode +
                ', ' +
                this.currentClinic.contactInformation.address.country
            );
        }
    }

    getClinicAddressOfficeNumber(): string {
        return this.currentClinic.contactInformation.address.officeNumber;
    }

    getClinicEmail(): string {
        return this.currentClinic.contactInformation.email;
    }

    getClinicPhoneNumber(): string {
        return this.currentClinic.contactInformation.phone;
    }

    getClinicCountryCode() {
        return this.currentClinic.contactInformation.address.country ? this.currentClinic.contactInformation.address.country.toLocaleLowerCase() : null;
    }

    getClinicCurrencyCode() {
        return appConfig.geographic.country[environment.countryCode].info.currencyCode;
    }

    setCurrentClinic(clinic: Clinic) {
        this.currentClinic = clinic;

        if (clinic) {
            localStorage.setItem('userClinic', clinic.id.toString());
            this.currentClinic$.next(clinic);
        }
    }

    getClinicTimeZone(): string {
        return this.currentClinic.timezone;
    }

    getClinicTimeZoneOffset(): string {
        return momentTimezone().tz(this.currentClinic.timezone).format('Z');
    }
}
