<router-outlet></router-outlet>

<app-toast key="alert-top-center" position="top-center" [showTransitionOptions]="'450ms ease-out'" [hideTransitionOptions]="'450ms ease-in'">
    <ng-template let-message pTemplate="message">
        <div class="icon-wrap"></div>
        <div
            *ngIf="message.data && message.data.detailIsHtml && message.detail"
            (click)="message.data.isOpen = !message.data.isOpen"
            [ngClass]="{ 'hide-details': message.data.isOpen }"
            class="icon-details"
        ></div>

        <div class="notif-content-wrap">
            <div class="cell">
                <p>{{ message.summary }}</p>
            </div>

            <div *ngIf="message.data && message.data.actionLabel" class="cell cta-wrap">
                <a (click)="message.data.onAction()">{{ message.data.actionLabel }}</a>
            </div>
        </div>
        <div
            *ngIf="message.data && message.data.detailIsHtml && message.detail"
            [innerHTML]="message.detail | safeHtml"
            [ngClass]="{ expand: message.data.isOpen }"
            class="details-container has-bold-text"
        ></div>
    </ng-template>
</app-toast>
