import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

import { AuthService } from 'src/app/shared/services/auth.service';
import { ClinicService } from 'src/app/shared/services/clinic.service';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/shared/services/language.service';
import { SelectItem } from 'primeng/api';
import { DropdownComponent } from 'src/app/shared/modules/dropdown/dropdown.component';
import { Clinic } from '@src/app/shared/models/Clinic';
import { environment } from 'src/environments/environment';
import { UserRole } from '@src/app/shared/models/User';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TopBarComponent implements OnInit, OnDestroy {
    currentClinic: Clinic;
    currentClinicName: string;
    currentLanguage: string;
    clinicItems: SelectItem[] = [];
    selectedClinicId: string;
    countryCode: string;
    lang: string;
    hasSVGSupport: boolean;
    fileExt: string;
    hasNotifBanner: boolean;

    private clinics: Clinic[];
    private isClinicless = false;
    private configurationsSubscription: Subscription;
    private langSubscription: Subscription;
    private clinicSubscription: Subscription;

    constructor(private authService: AuthService, private clinicService: ClinicService, private router: Router, private languageService: LanguageService) {}

    ngOnInit() {
        this.currentLanguage = this.languageService.getLang();

        this.hasSVGSupport = !!document.createElementNS && !!document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect;

        this.fileExt = this.hasSVGSupport ? 'svg' : 'png';

        const currentUser = this.authService.getUserLogged();
        const userRole = this.authService.getUserRole();

        this.currentClinic = this.clinicService.getCurrentClinic();

        if (this.currentClinic) {
            this.selectedClinicId = this.currentClinic.id;
            this.currentClinicName = this.getClinicName(this.currentClinic);
        } else {
            this.isClinicless = true;
        }

        if (userRole === UserRole.POMELO_ADMIN) {
            this.getAllClinic();
        } else {
            const requests = currentUser.user_metadata.clinicIds.map((clinicId) => {
                return this.clinicService.getClinicById(clinicId.toString());
            });

            forkJoin(requests).subscribe((clinics: Clinic[]) => {
                this.clinics = clinics;
                this.clinicItems = clinics
                    .map((clinic) => {
                        return {
                            label: this.getClinicName(clinic),
                            value: clinic.id,
                        };
                    })
                    .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
            });
        }

        this.clinicService.createClinic$.subscribe(() => {
            this.getAllClinic();
        });

        this.langSubscription = this.languageService.lang$.subscribe((lang) => {
            this.currentLanguage = lang;
            this.currentClinicName = this.getClinicName(this.currentClinic);

            if (userRole === UserRole.POMELO_ADMIN) {
                this.formatClinicItems(this.clinics);
            }
        });

        this.countryCode = environment.countryCode;
    }

    onMainPageRedirect(): void {
        if (this.currentClinic) {
            this.router.navigate(['/']);
        }
        return;
    }

    onDropDownFocus(dd: DropdownComponent) {
        if (!dd.selfClick) {
            dd.show();
        }
    }

    onInfoBannerShow(isShow: boolean) {
        setTimeout(() => {
            this.hasNotifBanner = isShow;
        }, 200);
    }

    onCurrentClinicChange() {
        if ((this.currentClinic && this.selectedClinicId !== this.currentClinic.id) || this.isClinicless) {
            this.currentClinic = this.clinics.find((c) => c.id === this.selectedClinicId);
            this.setSelectedClinic();
        }
    }

    private setSelectedClinic() {
        const currentRoute = this.router.url;

        return this.clinicService.getClinicById(this.currentClinic.id).subscribe((clinic) => {
            this.clinicService.setCurrentClinic(clinic);

            this.router.navigateByUrl('loading', { skipLocationChange: true }).then(() => {
                this.router.navigate([currentRoute]);
            });

            this.isClinicless = false;
        });
    }

    private getAllClinic() {
        this.clinicService.getAllClinic().subscribe((clinics) => {
            this.clinics = clinics;
            this.formatClinicItems(clinics);
        });
    }

    private formatClinicItems(clinics: Clinic[]) {
        this.clinicItems = clinics
            .sort((a, b) => {
                return parseInt(a.id, 10) - parseInt(b.id, 10);
            })
            .map((clinic) => {
                const label = `${this.getClinicName(clinic)} (${clinic.id})`;
                return {
                    label,
                    value: clinic.id,
                };
            });
    }

    private getClinicName(currentClinic: Clinic): string {
        if (currentClinic.contactInformation) {
            return currentClinic.contactInformation.name[this.currentLanguage]
                ? currentClinic.contactInformation.name[this.currentLanguage]
                : currentClinic.contactInformation.name.en;
        }
        return null;
    }

    ngOnDestroy() {
        if (this.configurationsSubscription) {
            this.configurationsSubscription.unsubscribe();
        }
        if (this.langSubscription) {
            this.langSubscription.unsubscribe();
        }
        if (this.clinicSubscription) {
            this.clinicSubscription.unsubscribe();
        }
    }
}
