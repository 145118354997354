import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SliderModule } from 'primeng/slider';
import { DrawerModule } from 'src/app/shared/modules/drawer/drawer.module';
import { TabMenuModule } from 'primeng/tabmenu';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TabViewModule } from 'primeng/tabview';

import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { FilterPipe } from './pipes/filter.pipe';
import { HeaderComponent } from './components/header/header.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { FeaturePermissionDirective } from './directives/feature-permission.directive';
import { PhonePipe } from './pipes/phone.pipe';
import { SafeHtmlPipe } from './pipes/sanitizer.pipe';
import { LanguagePipe } from './pipes/language.pipe';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MultiSelectModule } from './modules/multi-select/multi-select.module';
import { CalendarModule } from './modules/calendar/calendar.module';
import { UserCreateComponent } from './components/user-create/user-create.component';
import { DropdownModule } from './modules/dropdown/dropdown.module';
import { ClinicFormComponent } from './components/clinic/clinic-form.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { AddressAutocompleteComponent } from './components/address-autocomplete/address-autocomplete.component';
import { BaseSectionComponent } from './components/base-section/base-section.component';
import { AnchorNavigationComponent } from './components/anchor-navigation/anchor-navigation.component';
import { NotificationBannerComponent } from './components/notification-banner/notification-banner.component';

@NgModule({
    imports: [
        CommonModule,
        DropdownModule,
        MultiSelectModule,
        CalendarModule,
        FormsModule,
        ReactiveFormsModule,
        InputSwitchModule,
        QuillModule.forRoot(),
        SliderModule,
        TabMenuModule,
        TabViewModule,
        TranslateModule,
        DrawerModule,
        NgxSpinnerModule,
        MultiSelectModule,
    ],
    declarations: [
        AlertBoxComponent,
        ClinicFormComponent,
        FilterPipe,
        HeaderComponent,
        OrderByPipe,
        SideNavComponent,
        FeaturePermissionDirective,
        PhonePipe,
        SafeHtmlPipe,
        LanguagePipe,
        SpinnerComponent,
        UserCreateComponent,
        NumberOnlyDirective,
        AddressAutocompleteComponent,
        BaseSectionComponent,
        AnchorNavigationComponent,
        NotificationBannerComponent,
    ],
    exports: [
        AlertBoxComponent,
        ClinicFormComponent,
        FilterPipe,
        HeaderComponent,
        OrderByPipe,
        PhonePipe,
        SafeHtmlPipe,
        LanguagePipe,
        SideNavComponent,
        FeaturePermissionDirective,
        SpinnerComponent,
        UserCreateComponent,
        NumberOnlyDirective,
        AddressAutocompleteComponent,
        BaseSectionComponent,
        AnchorNavigationComponent,
        NotificationBannerComponent,
    ],
})
export class SharedModule {}
