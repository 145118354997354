import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { ClinicService } from './clinic.service';
import { appConfig } from 'src/app/app.config';
import { UserRole } from '../models/User';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class FeaturePermissionService {
    constructor(private authService: AuthService, private clinicService: ClinicService) {}

    isAuthorized(featureName: string): boolean {
        let condition = true;

        const permissions = appConfig.featurePermission[featureName];
        const role = permissions ? permissions.role : undefined;
        const permissionEnvironment = permissions ? permissions.environment : undefined;
        const country = permissions ? permissions.country : undefined;
        const config = permissions ? permissions.config : undefined;

        if (condition && role) {
            const roles = appConfig.roles;
            const userRole = this.authService.getUserRole();

            condition = roles.indexOf(UserRole[role]) <= roles.indexOf(UserRole[userRole]);
        }

        if (condition && permissionEnvironment) {
            condition = permissionEnvironment.includes(environment.name);
        }

        if (condition && country) {
            condition = country.includes(environment.countryCode);
        }

        if (condition && config) {
            const clinic = this.clinicService.getCurrentClinic();

            if (clinic) {
                if (permissions.configCondition === 'OR') {
                    condition = config.some((c) => clinic[c] && clinic[c] === true);
                } else {
                    condition = config.filter((c) => clinic[c] && clinic[c] === true).length === config.length;
                }
            }
        }

        return condition;
    }
}
