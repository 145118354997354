import { AfterViewInit, OnDestroy, Input, TemplateRef, Output, ViewChild, ElementRef, Component, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Message } from 'primeng/api';

@Component({
    selector: 'app-toast-item',
    templateUrl: './toast-item.component.html',
    animations: [
        trigger('messageState', [
            state(
                'visible',
                style({
                    transform: 'translateY(0)',
                })
            ),
            transition('void => *', [
                style({
                    transform: 'translateY(-1000px)',
                }),
                animate('{{showTransitionParams}}'),
            ]),
            transition('* => void', [
                animate(
                    '{{hideTransitionParams}}',
                    style({
                        transform: 'translateY(-1000px)',
                    })
                ),
            ]),
        ]),
    ],
})
export class ToastItemComponent implements AfterViewInit, OnDestroy {
    @Input() message: Message;

    @Input() index: number;

    @Input() template: TemplateRef<any>;

    @Input() showTransitionOptions: string;

    @Input() hideTransitionOptions: string;

    @Output() close = new EventEmitter();

    @ViewChild('container') containerViewChild: ElementRef;

    timeout: any;

    ngAfterViewInit() {
        this.initTimeout();
    }

    initTimeout() {
        if (!this.message.sticky) {
            this.timeout = setTimeout(() => {
                this.close.emit({
                    index: this.index,
                    message: this.message,
                });
            }, this.message.life || 3000);
        }
    }

    clearTimeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    onMouseEnter() {
        this.clearTimeout();
    }

    onMouseLeave() {
        this.initTimeout();
    }

    onCloseIconClick(event) {
        this.clearTimeout();

        this.close.emit({
            index: this.index,
            message: this.message,
        });

        event.preventDefault();
    }

    ngOnDestroy() {
        this.clearTimeout();
    }
}
