import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { FeaturePermissionService } from '../services/feature-permission.service';

@Injectable({
    providedIn: 'root',
})
export class FeaturePermissionGuard implements CanActivate {
    constructor(private router: Router, private featurePermissionService: FeaturePermissionService) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const condition = this.featurePermissionService.isAuthorized(route.data.featureName);

        if (condition) {
            return of(condition);
        }

        this.router.navigate(['/']);
        return of(condition);
    }
}
