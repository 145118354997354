import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { FeaturePermissionGuard } from './shared/guards/feature-permission.guard';
import { CliniclessGuard } from './shared/guards/clinicless.guard';
import { MaintenanceGuard } from './shared/guards/maintenance.guard';
import { DummyComponent } from './layouts/dummy/dummy.component';
import { CliniclessComponent } from './modules/clinicless/clinicless.component';
import { MaintenanceComponent } from './shared/components/maintenance/maintenance.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'magicseat',
        pathMatch: 'full',
    },
    {
        path: '',
        component: ContentLayoutComponent,
        canActivate: [MaintenanceGuard, AuthGuard],
        children: [
            {
                path: 'clinicless',
                canActivate: [CliniclessGuard],
                component: CliniclessComponent,
            },
            {
                path: 'loading',
                component: DummyComponent,
            },
            {
                path: 'account-settings',
                loadChildren: () => import('./modules/account-settings/account-settings.module').then((m) => m.AccountSettingsModule),
            },
            {
                path: 'magicseat',
                loadChildren: () => import('./modules/magicseat/magicseat.module').then((m) => m.MagicseatModule),
            },
            {
                path: 'ui',
                loadChildren: () => import('./modules/style-guide/style-guide.module').then((m) => m.StyleGuideModule),
                canActivate: [FeaturePermissionGuard],
                data: {
                    featureName: 'styleGuide',
                },
            },
        ],
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router) {
        this.router.errorHandler = () => {
            this.router.navigate(['']);
        };
    }
}
