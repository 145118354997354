import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { AnchorNav } from '@src/app/shared/models/AnchorNav';
import { ToolsService } from '@app/shared/services/tools.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-anchor-navigation',
    templateUrl: './anchor-navigation.component.html',
    styleUrls: ['./anchor-navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AnchorNavigationComponent implements AfterViewInit, OnDestroy {
    @Input() navigation: AnchorNav[];

    @ViewChildren('ancnavLinks') ancnavLinks: QueryList<ElementRef>;

    opened = false;
    observer;
    isNavigating = false;

    constructor(private toolsService: ToolsService, @Inject(DOCUMENT) private document) {}

    ngAfterViewInit(): void {
        this.observer = new IntersectionObserver(this.onIntersectionChanges.bind(this), {
            root: null,
            rootMargin: '0px',
            threshold: 0.25,
        });
        this.document.querySelectorAll('[data-ancnav]').forEach((ancnav) => this.observer.observe(ancnav));
    }

    ngOnDestroy(): void {
        this.observer.disconnect();
    }

    onGripClick() {
        this.opened = !this.opened;
        if (this.opened) {
            this.toolsService.addBodyClass('ancnav-open');
        } else {
            setTimeout(() => {
                this.toolsService.removeBodyClass('ancnav-open');
            }, 200);
        }
    }

    onTopAnchorClick(e) {
        e.preventDefault();

        this.isNavigating = true;
        this.toolsService.scrollIntoView('body');

        setTimeout(() => {
            this.isNavigating = false;
            this.setAnchorActiveStates();
        }, 750);
    }

    onAnchorClick(e) {
        e.preventDefault();

        this.isNavigating = true;
        this.toolsService.scrollIntoView(`[data-ancnav="${e.target.getAttribute('href')}"]`);

        setTimeout(() => {
            this.isNavigating = false;
            this.setAnchorActiveStates(e.target);
        }, 750);
    }

    onIntersectionChanges(changes) {
        if (!this.isNavigating) {
            changes.forEach((change) => {
                if (change.isIntersecting) {
                    this.ancnavLinks.forEach((link) => {
                        if (link.nativeElement.getAttribute('href') === change.target.dataset.ancnav) {
                            link.nativeElement.dataset.isActive = (
                                (this.document.body.dataset.scrollDir === 'down' && change.boundingClientRect.top > 0) ||
                                (this.document.body.dataset.scrollDir === 'up' && change.boundingClientRect.top < 0)
                            ).toString();
                        }
                    });
                }
            });
        }
    }

    setAnchorActiveStates(el = null) {
        this.ancnavLinks.forEach((link) => {
            link.nativeElement.dataset.isActive = (el === link.nativeElement).toString();
        });
    }
}
