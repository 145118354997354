<div class="view-header-container">
    <div class="view-header-container__left">
        <h1 *ngIf="!tabs" class="title title--h1" data-test="mainTitle">
            {{ title }}
        </h1>
        <p-tabMenu *ngIf="tabs" [model]="tabs" data-test="mainTabs"></p-tabMenu>
    </div>
    <div class="action-container">
        <div [hidden]="!showBtn">
            <p>{{ changesText }}</p>
            <button type="button" class="btn btn--border" (click)="cancel()">
                {{ cancelText }}
            </button>
            <button type="button" class="btn" [ngClass]="{ 'btn--loading': isSaving }" (click)="save()">
                {{ saveText }}
            </button>
        </div>
        <div *appFeaturePermission="'harvest'" [hidden]="!harvestLabel">
            <button type="button" class="btn btn--no-border btn--sm" (click)="onHarvest()">
                <span class="icon icon-refresh--sm"></span>
                {{ harvestLabel }}
            </button>
        </div>
    </div>
</div>
