import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, Type } from '@angular/core';
import { DrawerWrapperComponent } from './drawer-wrapper.component';
import { Sidebar } from 'primeng/sidebar';

@Component({
    selector: 'app-drawer',
    templateUrl: './drawer.component.html',
})
export class DrawerComponent {
    @ViewChild('element', { static: true, read: ViewContainerRef })
    public element: ViewContainerRef;
    @ViewChild('drawer') sidebar: Sidebar;

    drawerWrapper: DrawerWrapperComponent;
    isActive = false;
    style = 'ui-sidebar-md';
    position = 'right';
    options: any = {};

    constructor(private resolver: ComponentFactoryResolver) {}

    addDrawer<T>(component: Type<T>, options?: any) {
        this.options = options || ({} as any);

        if (this.options.dismissible !== false) {
            this.options.dismissible = true;
        }

        if (this.options.style) {
            this.style = this.options.style;
        }
        if (this.options.position) {
            this.position = this.options.position;
        }

        const factory = this.resolver.resolveComponentFactory(DrawerWrapperComponent);
        const componentRef = this.element.createComponent(factory);
        this.drawerWrapper = componentRef.instance as DrawerWrapperComponent;
        const componentInstance = this.drawerWrapper.addComponent<T>(component);

        this.isActive = true;

        return componentInstance;
    }

    onClose() {
        this.isActive = false;
        this.element.remove();
    }

    onHide() {
        this.element.remove();
    }
}
