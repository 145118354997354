<nav class="dd-menu" (window:scroll)="menu.hide()">
    <a (click)="menu.toggle($event)">
        <span class="icon icon-burger-menu--sm" [ngClass]="{ 'icon-burger-menu--active': isOpen }"></span>
    </a>

    <p-overlayPanel #menu appendTo="body" (onShow)="onToogleMenu()" (onHide)="onToogleMenu()">
        <div class="dd-menu__info">
            <div class="dd-menu__user logged-user-info">
                <p>{{ user.nickname }}</p>
                <span class="ui-meta">{{ user.email }}</span>
            </div>
            <ul class="dd-menu__menu menu-icon">
                <li class="menu-icon__item" *appFeaturePermission="'userCreate'" (click)="menu.hide()">
                    <a (click)="createUserForm()">
                        <span class="icon icon-new-user"></span>
                        {{ 'app.side_menu.create_user' | translate }}
                    </a>
                </li>
                <li class="menu-icon__item" *appFeaturePermission="'createClinic'" (click)="menu.hide()">
                    <a (click)="showClinicForm()">
                        <span class="icon icon-clinic"></span>
                        {{ 'app.side_menu.create_clinic' | translate }}
                    </a>
                </li>
                <li class="menu-icon__item" *ngIf="hasSelectedClinic" (click)="menu.hide()">
                    <a routerLink="/account-settings" routerLinkActive="active">
                        <span class="icon icon-settings"></span>
                        {{ 'app.side_menu.account_settings' | translate }}
                    </a>
                </li>
                <li class="menu-icon__item" *appFeaturePermission="'styleGuide'" (click)="menu.hide()">
                    <a routerLink="/ui" routerLinkActive="active">
                        <span class="icon icon-style-guide"></span>
                        Style Guide
                    </a>
                </li>
                <li class="menu-icon__item" (click)="menu.hide()">
                    <a (click)="logout()">
                        <span class="icon icon-log-out"></span>
                        {{ 'app.side_menu.logout' | translate }}
                    </a>
                </li>
            </ul>
        </div>
    </p-overlayPanel>
</nav>
