import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'primeng/api';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { MultiSelectComponent } from './multi-select.component';
import { MultiSelectItemComponent } from './multi-select-item/multi-select-item.compnent';

@NgModule({
    imports: [CommonModule, SharedModule, ScrollingModule],
    exports: [MultiSelectComponent, SharedModule, ScrollingModule],
    declarations: [MultiSelectComponent, MultiSelectItemComponent],
})
export class MultiSelectModule {}
