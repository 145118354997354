export const environment = {
    name: 'dev',
    production: false,
    enableProdMode: false,
    api: 'https://devapi.chronometriq.com/api/',
    walkinapi: '/api/',
    auth: {
        client_id: 'sLMArZwSlWS5AF1c1qXCO3ZcH32KAWtI',
        domain: 'dev-auth.pomelo.health',
        audience: 'https://walkin.services.dev.ca-a.api.pomelo.health/',
    },
    amazon: {
        bucketName: 'devportailweb',
        url: 'https://s3.amazonaws.com/devportailweb/',
        region: 'us-east-1',
    },
    walkin: {
        en: {
            medical: 'https://walkin.services.dev.ca-a.api.pomelo.health/#/clinic/',
        },
        es: {
            medical: 'https://walkin.services.dev.ca-a.api.pomelo.health/#/clinic/',
        },
        fr: {
            medical: 'https://walkin.services.dev.ca-a.api.pomelo.health/#/clinic/',
        },
    },
    qhr: {
        url: 'https://q1-accapi.accuroemr.com/oauth/authorize?response_type=code&client_id=mnt70dmikku5n4oih0sk7lemp7',
    },
    countryCode: 'ca',
    dynatrace: null,
    maintenance: {
        url: 'https://pomelo.services.dev.ca-a.api.pomelo.health/common-status/public/',
        page: 'https://devpages.chronometriq.com/#/pages/maintenance/',
    },
};
