import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Status } from '../models/Status';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceService {
    private url = 'status';

    constructor(private http: HttpClient) {
        this.url = environment.maintenance.url.concat(this.url);
    }

    getStatus(): Observable<Status> {
        return this.http.get<Status>(this.url, {
            headers: {
                applicationId: 'FRONT_WALKIN_DASHBOARD',
            },
        });
    }
}
