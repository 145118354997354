<div class="form-title">
    <h1 class="title title--h1alt">
        {{ 'app.user_create.form.title' | translate }}
    </h1>
</div>

<form class="user-create-form form__grid" [formGroup]="userCreateForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="form__item">
        <div class="form__group">
            <label class="form__item__label">{{ 'app.user_create.form.first_name' | translate }}</label>
            <input class="form__control" type="text" name="firstName" formControlName="user-firstname" autocomplete="off" />
        </div>
    </div>

    <div class="form__item">
        <div class="form__group">
            <label class="form__item__label">{{ 'app.user_create.form.last_name' | translate }}</label>
            <input class="form__control" type="text" name="lastName" formControlName="user-lastname" autocomplete="off" />
        </div>
    </div>

    <div class="form__item">
        <div
            class="form__group"
            [ngClass]="{
                'form__group--error': userCreateForm.get('user-email').touched && userCreateForm.get('user-email').errors
            }"
        >
            <label class="form__item__label">{{ 'app.user_create.form.email' | translate }}</label>
            <input class="form__control" type="email" name="email" formControlName="user-email" autocomplete="off" />
        </div>
    </div>

    <div class="form__item">
        <div class="form__group">
            <label class="form__item__label">{{ 'app.user_create.form.password' | translate }}</label>
            <input class="form__control" type="password" name="pass" formControlName="user-pass" autocomplete="off" />
        </div>
    </div>

    <div class="form__item">
        <div class="form__group">
            <label class="form__item__label">{{ 'app.user_create.form.lang_switch_label' | translate }}</label>

            <div class="ui-radio">
                <div *ngFor="let lang of supportedLanguages" class="ui-radio__item">
                    <input name="select-lang" type="radio" id="radio-lang-{{ lang.key }}" value="{{ lang.key }}" formControlName="select-lang" />
                    <div class="styled-radio-box ui-radio__box"></div>
                    <label for="radio-lang-{{ lang.key }}" class="form__item__label ui-radio__label">{{ lang.label }}</label>
                </div>
            </div>
        </div>
    </div>

    <div class="form__item">
        <div class="form__group">
            <label class="form__item__label">{{ 'app.user_create.form.clinic_select_label' | translate }}</label>
            <app-multi-select
                #clinicMultiSelect
                class="dropdown dropdown--full"
                (onChange)="onSelectedClinicChange(clinicMultiSelect)"
                [options]="clinicItems"
                formControlName="select-clinics"
                [resetFilterOnHide]="true"
                [filter]="true"
                defaultLabel="{{ 'app.user_create.form.select_clinic' | translate }}"
                filterPlaceHolder="{{ 'app.user_create.form.search_clinic' | translate }}"
                selectAllLabel="{{ 'app.user_create.form.select_all' | translate }}"
                selectCountLabel="{{ multiSelectCount | i18nPlural: clinicSelectedLabel | translate }}"
            ></app-multi-select>
        </div>
    </div>

    <div class="form__item">
        <div class="form__group">
            <label class="form__item__label">{{ 'app.user_create.form.user_role_label' | translate }}</label>
            <app-dropdown
                class="dropdown dropdown--min-width"
                [options]="userRoles"
                (onChange)="onSelectedRole()"
                placeholder="{{ 'app.user_create.form.select_role' | translate }}"
                formControlName="select-role"
            ></app-dropdown>
        </div>
    </div>

    <div class="save-btn-container">
        <button type="button" class="btn btn--border" (click)="onCancel()">
            {{ 'app.user_create.form.btn_cancel' | translate }}
        </button>
        <button type="submit" class="btn" [ngClass]="{ 'btn--loading': isSendingUserCreateForm }" [disabled]="!userCreateForm.valid">
            {{ 'app.user_create.form.btn_create' | translate }}
        </button>
    </div>
</form>
