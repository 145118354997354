<div class="alert-box" [attr.data-theme]="type" data-test="alertBox">
    <span class="alert-box__icon">
        <span class="icon icon-delete"></span>
    </span>
    <div class="alert-box__content" data-test="alertBoxContent">
        <ng-container *ngIf="!content">
            <p [innerHTML]="message | safeHtml"></p>
        </ng-container>
        <ng-container *ngIf="content">
            <ng-content></ng-content>
        </ng-container>
    </div>
</div>
