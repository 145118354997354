import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dummy',
    template: '',
})
export class DummyComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
