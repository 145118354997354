import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'app-multi-select-item',
    templateUrl: './multi-select-item.component.html',
})
export class MultiSelectItemComponent {
    @Input() option: SelectItem;

    @Input() selected: boolean;

    @Input() disabled: boolean;

    @Input() visible: boolean;

    @Input() itemSize: number;

    @Input() template: TemplateRef<any>;

    @Input() maxSelectionLimitReached: boolean;

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    @Output() onKeydown: EventEmitter<any> = new EventEmitter();

    onOptionClick(event: Event) {
        this.onClick.emit({
            originalEvent: event,
            option: this.option,
        });
    }

    onOptionKeydown(event: Event) {
        this.onKeydown.emit({
            originalEvent: event,
            option: this.option,
        });
    }
}
