import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ClinicService } from '../services/clinic.service';

@Injectable({
    providedIn: 'root',
})
export class CliniclessGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService, private clinicService: ClinicService) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const isClinicless = this.authService.isCliniclessUser();
        const hasClinic = !!this.clinicService.getCurrentClinic();

        const isAuthorized = !isClinicless || (isClinicless && hasClinic);

        if (isAuthorized) {
            this.router.navigate(['/']);
        }

        return of(!isAuthorized);
    }
}
