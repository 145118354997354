import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { LanguageService } from '../../services/language.service';

@Component({
    selector: 'app-notification-banner',
    templateUrl: './notification-banner.component.html',
    styleUrls: ['./notification-banner.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NotificationBannerComponent implements OnInit, OnDestroy {
    @Input() startDate: string;
    @Input() endDate: string;

    @Output() hasNotifBanner = new EventEmitter();

    // TODO: Transform the component to a custom notification banner
    isVisible: boolean;
    lang: string;
    hasSVGSupport: boolean;
    fileExt: string;

    private langSubscription: Subscription;

    constructor(private languageService: LanguageService) {}

    ngOnInit() {
        this.lang = this.languageService.getLang();
        this.hasSVGSupport = !!document.createElementNS && !!document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect;
        this.fileExt = this.hasSVGSupport ? 'svg' : 'png';

        const start = moment(this.startDate, 'YYYY-MM-DD');
        const end = moment(this.endDate, 'YYYY-MM-DD');

        if (moment().isBetween(start, end) && localStorage.getItem('banner-chronometriq-becomes-pomelo-health') !== 'closed') {
            this.isVisible = true;
            this.hasNotifBanner.emit(this.isVisible);
        } else {
            this.isVisible = false;
            this.hasNotifBanner.emit(this.isVisible);
        }

        this.langSubscription = this.languageService.lang$.subscribe((lang) => {
            this.lang = lang;
        });
    }

    onClose(): void {
        this.isVisible = false;
        this.hasNotifBanner.emit(this.isVisible);

        localStorage.setItem('banner-chronometriq-becomes-pomelo-health', 'closed');
    }

    ngOnDestroy() {
        if (this.langSubscription) {
            this.langSubscription.unsubscribe();
        }
    }
}
