import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HeaderService {
    showBtn$: Subject<boolean>;

    private accept: () => void;
    private reject: () => void;

    constructor() {
        this.showBtn$ = new Subject<boolean>();
    }

    showBtn(options: { accept: () => void; reject: () => void }) {
        this.accept = options.accept;
        this.reject = options.reject;

        this.showBtn$.next(true);
    }

    hideBtn() {
        this.showBtn$.next(false);
    }

    save() {
        this.accept();
    }

    cancel() {
        this.reject();
    }
}
