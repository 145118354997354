import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Event as RouterEvent, NavigationStart, NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { LanguageService } from './shared/services/language.service';
import { ToolsService } from 'src/app/shared/services/tools.service';
import { appConfig } from './app.config';
import { filter } from 'rxjs/operators';
import { ClinicService } from './shared/services/clinic.service';
import { environment } from '@src/environments/environment';

declare var dT_;
declare var ga;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private langSubscription: Subscription;

    constructor(
        private router: Router,
        private http: HttpClient,
        private translate: TranslateService,
        private languageService: LanguageService,
        private spinner: NgxSpinnerService,
        public toolsService: ToolsService,
        private clinicService: ClinicService
    ) {
        if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
            dT_.initAngularNg(http, HttpHeaders);
        }

        const browserLang = translate.getBrowserLang();
        let lang = 'en';

        if (localStorage.getItem('cmqlang') && this.isLangAvailable(localStorage.getItem('cmqlang'))) {
            lang = localStorage.getItem('cmqlang');
        } else if (this.isLangAvailable(browserLang)) {
            lang = browserLang;
        }

        this.translate.setDefaultLang(lang);
        this.languageService.setLang(lang);

        router.events.subscribe((event: RouterEvent): void => {
            if (event instanceof NavigationStart) {
                this.spinner.show('pageLoader');
            } else if (event instanceof NavigationEnd) {
                this.spinner.hide('pageLoader');
            }
        });

        // Google analytics
        const navEndEvent = router.events.pipe(filter((e) => e instanceof NavigationEnd));
        navEndEvent.subscribe((e: NavigationEnd) => {
            ga('set', 'page', e.urlAfterRedirects);
            ga('send', 'pageview');
        });

        this.clinicService.currentClinic$.subscribe((clinic) => {
            this.setGoogleAnalyticsDimensions();
        });
    }

    ngOnInit(): void {
        this.toolsService.setTheme('default');
        this.langSubscription = this.languageService.lang$.subscribe((lang) => {
            this.translate.use(lang);
            this.setGoogleAnalyticsDimensions();
        });
    }

    private isLangAvailable(lang: string): boolean {
        return appConfig.supportedLanguages.some((availableLang) => availableLang.key === lang);
    }

    private setGoogleAnalyticsDimensions() {
        const clinicName = this.clinicService.getCurrentClinic().contactInformation.name[this.languageService.getLang()]
            ? this.clinicService.getCurrentClinic().contactInformation.name[this.languageService.getLang()]
            : this.clinicService.getCurrentClinic().contactInformation.name.en;

        if (!environment.production) {
            console.log(
                'ga service - dimensions: ',
                JSON.stringify({
                    dimension1: this.clinicService.getClinicId(),
                    dimension2: clinicName,
                })
            );
        }
        ga('set', {
            dimension1: this.clinicService.getClinicId(),
            dimension2: clinicName,
        });
    }

    ngOnDestroy() {
        if (this.langSubscription) {
            this.langSubscription.unsubscribe();
        }
    }
}
