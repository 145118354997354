import { Component, ViewContainerRef, ViewChild, ComponentFactoryResolver, ReflectiveInjector, Type } from '@angular/core';

@Component({
    selector: 'app-drawer-wrapper',
    templateUrl: './drawer-wrapper.component.html',
})
export class DrawerWrapperComponent {
    @ViewChild('element', { static: true, read: ViewContainerRef })
    public element: ViewContainerRef;
    @ViewChild('container') public container;

    isActive = true;

    private content: any;

    constructor(private resolver: ComponentFactoryResolver) {}

    addComponent<T>(component: Type<T>) {
        const factory = this.resolver.resolveComponentFactory(component);
        const injector = ReflectiveInjector.fromResolvedProviders([], this.element.injector);
        const componentRef = factory.create(injector);
        this.element.insert(componentRef.hostView);
        this.content = componentRef.instance;
        this.content.wrapper = this;
        return this.content;
    }
}
