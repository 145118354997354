<ul class="side-nav-container side-nav" data-test="siteNav">
    <li *ngFor="let item of items" class="side-nav__item">
        <a class="side-nav__link" [routerLink]="item.link" *appFeaturePermission="item.featureName" routerLinkActive="active">
            {{ item.label }}
        </a>
    </li>
</ul>

<div class="side-nav-mobile">
    <app-dropdown class="dropdown" [options]="settingsItems" [(ngModel)]="settingsItemSelected" (onChange)="onSettingsItemsChange()"></app-dropdown>
</div>
