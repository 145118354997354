import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'app-dropdown-item',
    templateUrl: './dropdown-item.component.html',
})
export class DropdownItemComponent {
    @Input() option: SelectItem;
    @Input() selected: boolean;
    @Input() disabled: boolean;
    @Input() visible: boolean;
    @Input() itemSize: number;
    @Input() template: TemplateRef<any>;

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    onOptionClick(event: Event) {
        this.onClick.emit({
            originalEvent: event,
            option: this.option,
        });
    }
}
