import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationStatus } from '../../models/Status';
import { LanguageService } from '../../services/language.service';
import { MaintenanceService } from '../../services/maintenance.service';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit, OnDestroy {
    maintenancePage: string;

    private maintenanceSubscription: Subscription;

    constructor(private maintenanceService: MaintenanceService, private router: Router, private languageService: LanguageService) {}

    ngOnInit(): void {
        this.maintenancePage = environment.maintenance.page + this.languageService.getLang();
        this.maintenanceSubscription = this.maintenanceService.getStatus().subscribe((resp) => {
            if (resp.status === ApplicationStatus.Running) {
                this.router.navigate(['']);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.maintenanceSubscription) {
            this.maintenanceSubscription.unsubscribe();
        }
    }
}
