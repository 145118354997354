/*
 * Example use
 *		Basic Array of single type: *ngFor="#todo of todoService.todos | orderBy : '-'"
 *		Multidimensional Array Sort on single column: *ngFor="#todo of todoService.todos | orderBy : ['-status']"
 *		Multidimensional Array Sort on multiple columns: *ngFor="#todo of todoService.todos | orderBy : ['status', '-title']"
 * http://fuelinteractive.github.io/fuel-ui/#/pipe/orderby
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
    pure: false,
})
export class OrderByPipe implements PipeTransform {
    static _orderByComparator(a: any, b: any): number {
        if (typeof a === 'boolean' || typeof b === 'boolean') {
            return a === b ? 0 : a ? -1 : 1;
        } else if (isNaN(parseFloat(a)) || !isFinite(a) || isNaN(parseFloat(b)) || !isFinite(b)) {
            if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
            }
            if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
            }
        } else {
            if (parseFloat(a) < parseFloat(b)) {
                return -1;
            }
            if (parseFloat(a) > parseFloat(b)) {
                return 1;
            }
        }

        return 0;
    }

    transform(input: any, fields: string[]): any {
        if (!Array.isArray(input)) {
            return input;
        }

        if (!Array.isArray(fields) || (Array.isArray(fields) && fields.length === 1)) {
            const propertyToCheck: string = !Array.isArray(fields) ? fields : fields[0];
            const desc = propertyToCheck.substr(0, 1) === '-';

            if (!propertyToCheck || propertyToCheck === '-' || propertyToCheck === '+') {
                return !desc ? input.sort() : input.sort().reverse();
            } else {
                const property: string = propertyToCheck.substr(0, 1) === '+' || propertyToCheck.substr(0, 1) === '-' ? propertyToCheck.substr(1) : propertyToCheck;

                return input.sort((a: any, b: any) => {
                    return !desc ? OrderByPipe._orderByComparator(a[property], b[property]) : -OrderByPipe._orderByComparator(a[property], b[property]);
                });
            }
        } else {
            return input.sort((a: any, b: any) => {
                for (const field of fields) {
                    const desc = field.substr(0, 1) === '-';
                    const property = field.substr(0, 1) === '+' || field.substr(0, 1) === '-' ? field.substr(1) : field;

                    const comparison = !desc ? OrderByPipe._orderByComparator(a[property], b[property]) : -OrderByPipe._orderByComparator(a[property], b[property]);

                    if (comparison !== 0) {
                        return comparison;
                    }
                }

                return 0;
            });
        }
    }
}
