<li
    (click)="onOptionClick($event)"
    role="option"
    [attr.aria-label]="option.label"
    [ngStyle]="{ height: itemSize + 'px' }"
    [ngClass]="{
        'ui-dropdown-item': true,
        'ui-state-highlight': selected,
        'ui-state-disabled': option.disabled,
        'ui-dropdown-item-empty': !option.label || option.label.length === 0
    }"
>
    <span *ngIf="!template">{{ option.label || 'empty' }}</span>
    <ng-container *ngTemplateOutlet="template; context: { $implicit: option }"></ng-container>
</li>
