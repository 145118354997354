import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import * as moment from 'moment-timezone';

import { Subscription } from 'rxjs';
import { Language } from 'src/app/shared/models/AppConfig';
import { appConfig } from 'src/app/app.config';
import { environment } from 'src/environments/environment';
import { ClinicService } from '../../services/clinic.service';
import { noWhiteSpaceValidator } from '../../directives/no-white-space.directive';
import { Address } from '../../models/Clinic';

@Component({
    selector: 'app-clinic-form',
    templateUrl: './clinic-form.component.html',
    styleUrls: ['./clinic-form.component.scss'],
})
export class ClinicFormComponent implements OnInit, OnDestroy {
    @Output() save = new EventEmitter();
    @Output() cancel = new EventEmitter();

    countryCode: string;
    clinicForm: FormGroup;
    languages: string[] = [];
    names: FormArray;
    isSendingClinicCreateForm = false;
    isUSClinic: boolean;
    timezoneList: SelectItem[];
    provinces: SelectItem[];
    countries: SelectItem[];
    xCoords = '0';
    zCoords = '0';

    address: Address;
    addressPreview: {
        civic: string;
        office: string;
        city: string;
        province: string;
        postalCode: string;
        country: string;
        formatted: string;
    };

    private configurationSubscription: Subscription;

    constructor(private clinicService: ClinicService) {}

    ngOnInit() {
        this.countryCode = environment.countryCode;

        this.countries = [
            {
                label: appConfig.geographic.country[this.countryCode].info.label,
                value: appConfig.geographic.country[this.countryCode].info.code,
            },
        ];

        this.provinces = appConfig.geographic.country[this.countryCode].province
            .map((province) => {
                return {
                    label: province.label,
                    value: province.code,
                };
            })
            .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

        this.clinicForm = new FormGroup({
            names: new FormArray([], this.validateNames),
            addressCivic: new FormControl('', [Validators.required]),
            addressOffice: new FormControl(''),
            addressCity: new FormControl('', [Validators.required]),
            addressPostalCode: new FormControl('', [Validators.required, noWhiteSpaceValidator()]),
            addressProvince: new FormControl('', [Validators.required]),
            addressCountry: new FormControl('', [Validators.required]),
            selectedTimezone: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.pattern(appConfig.regex.email)]),
        });

        this.names = this.clinicForm.get('names') as FormArray;

        const supportedLanguages: Language[] = appConfig.supportedLanguages;

        for (const language of supportedLanguages) {
            this.languages.push(language.key);
            this.names.push(
                new FormGroup({
                    [language.key]: new FormControl(''),
                })
            );
        }

        this.formatTimezoneList();
    }

    onAddressAutocompleteChange(address: Address) {
        this.address = address;

        this.clinicForm.patchValue({
            addressCivic: `${this.address.streetNumber} ${this.address.streetName}`,
            addressOffice: this.clinicForm.get('addressOffice').value,
            addressCity: this.address.city,
            addressPostalCode: this.address.postalCode,
            addressProvince: this.address.province,
            addressCountry: this.address.country,
        });

        this.addressPreview = {
            civic: `${this.address.streetNumber} ${this.address.streetName}`,
            office: this.clinicForm.get('addressOffice').value,
            city: this.address.city,
            postalCode: this.address.postalCode,
            province: this.address.province,
            country: this.address.country,
            formatted: this.address.formatted,
        };

        this.xCoords = this.address.xcoords;
        this.zCoords = this.address.zcoords;

        this.formatAddressUpdate();
    }

    onTimezoneChange(tzOffset: number) {
        let selectedTimezone = null;

        const timezones = this.timezoneList.filter((tz) => {
            return tz.value.offset === tzOffset;
        });

        if (timezones.length > 1) {
            selectedTimezone = timezones.find((tz) => {
                return tz.value.label === this.address.city;
            });

            if (!selectedTimezone) {
                selectedTimezone = timezones[0];
            }
        } else {
            selectedTimezone = timezones[0];
        }

        this.clinicForm.patchValue({
            selectedTimezone: selectedTimezone.value,
        });
    }

    onAddressEdit() {
        this.addressPreview = {
            civic: this.clinicForm.get('addressCivic').value.trim(),
            office: this.clinicForm.get('addressOffice').value.trim(),
            city: this.clinicForm.get('addressCity').value.trim(),
            province: this.clinicForm.get('addressProvince').value,
            postalCode: this.clinicForm.get('addressPostalCode').value.trim(),
            country: this.clinicForm.get('addressCountry').value,
            formatted: '',
        };

        this.formatAddressUpdate();

        if (this.address && this.addressPreview.civic !== `${this.address.streetNumber} ${this.address.streetName}`) {
            this.xCoords = '0';
            this.zCoords = '0';
        }
    }

    onCancelCreateClinic() {
        this.cancel.emit();
    }

    onSubmit() {
        const nameEn = this.getClinicNameByLang('en');
        const nameFr = this.isUSClinic ? this.getClinicNameByLang('es') : this.getClinicNameByLang('fr');

        const civicAddrArr = this.splitCivicAddress();

        if (this.clinicForm.valid) {
            this.isSendingClinicCreateForm = true;

            const clinic = {
                timezone: this.clinicForm.get('selectedTimezone').value.name,
                languages: appConfig.supportedLanguages.map((lang) => lang.key),
                contactInformation: {
                    email: this.clinicForm.get('email').value,
                    address: {
                        streetNumber: civicAddrArr.streetNumber,
                        streetName: civicAddrArr.streetName,
                        officeNumber: this.clinicForm.get('addressOffice').value.trim(),
                        city: this.clinicForm.get('addressCity').value.trim(),
                        country: this.clinicForm.get('addressCountry').value,
                        province: this.clinicForm.get('addressProvince').value,
                        postalCode: this.clinicForm.get('addressPostalCode').value.trim(),
                        xcoords: this.xCoords,
                        zcoords: this.zCoords,
                        formatted: this.addressPreview.formatted.trim(),
                    },
                    name: {
                        en: nameEn ? nameEn : nameFr,
                        [environment.countryCode === 'ca' ? 'fr' : 'es']: nameFr ? nameFr : nameEn,
                    },
                },
            };

            this.clinicService.create(clinic).subscribe(() => {
                this.save.emit();
                this.isSendingClinicCreateForm = false;
            });
        }
    }

    private splitCivicAddress() {
        const newCivicAddrArr = this.clinicForm.get('addressCivic').value.split(' ');
        const result = {
            streetNumber: newCivicAddrArr[0],
            streetName: '',
        };

        for (let i = 0; i < newCivicAddrArr.length; i++) {
            if (i !== 0) {
                result.streetName += newCivicAddrArr[i] + ' ';
            }
        }

        result.streetName = result.streetName.trim();

        return result;
    }

    private formatAddressUpdate() {
        const civic = this.addressPreview.civic ? `${this.addressPreview.civic}, ` : '';
        const office = this.addressPreview.office ? `${this.addressPreview.office}, ` : '';
        const city = this.addressPreview.city ? `${this.addressPreview.city}, ` : '';
        const postalCode = this.addressPreview.postalCode ? `${this.addressPreview.postalCode}, ` : '';
        const province = this.addressPreview.province ? `${this.addressPreview.province}, ` : '';
        const country = this.addressPreview.country ? this.addressPreview.country : '';

        this.addressPreview.formatted = civic + office + city + province + postalCode + country;
    }

    private formatTimezoneList() {
        const timezones = appConfig.geographic.country[this.countryCode].timezone.map((timezone) => {
            return {
                label: timezone.label,
                name: timezone.code,
                offset: moment.tz(timezone.code).utcOffset(),
                utc: timezone.utc,
                tz: timezone.tz,
            };
        });

        this.timezoneList = timezones
            .map((timezone) => {
                return {
                    label: timezone.label,
                    value: timezone,
                };
            })
            .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    }

    private validateNames(arr: FormArray) {
        const found = (arr.value as Array<any>).find((values) => {
            for (const value of Object.keys(values)) {
                return values[value];
            }

            return false;
        });

        return found
            ? null
            : {
                  invalidName: true,
              };
    }

    private getClinicNameByLang(lang: string) {
        const index = this.languages.indexOf(lang);
        return (this.clinicForm.get('names') as FormArray).controls[index].get(lang).value;
    }

    ngOnDestroy() {
        if (this.configurationSubscription) {
            this.configurationSubscription.unsubscribe();
        }
    }
}
