<div class="form-title">
    <h1 class="title title--h1alt">
        {{ 'app.clinic.form.title' | translate }}
    </h1>
    <p class="ui-caption">
        {{ 'app.clinic.form.complete_addr.required' | translate }}
    </p>
</div>

<form class="clinic-form" [formGroup]="clinicForm" (ngSubmit)="onSubmit()" class="clinic-form-container">
    <div>
        <div class="text-separator">
            <h2 class="title title--h2 fw-bold">
                {{ 'app.clinic.form.basic_info' | translate }}
            </h2>
        </div>
        <div class="form__grid">
            <div class="form__item toggle-wrap">
                <ng-container formArrayName="names">
                    <p-tabView class="toggle-type-tabview">
                        <p-tabPanel [header]="language" *ngFor="let language of languages; let i = index" [selected]="i == 0">
                            <div
                                [formGroupName]="i"
                                class="form__group"
                                [ngClass]="{
                                    'form__group--error': clinicForm.controls.names.controls[i].controls[languages[i]].touched && clinicForm.controls.names.errors
                                }"
                            >
                                <label class="form__item__label">{{ 'app.clinic.form.name' | translate }}</label>
                                <input type="text" [formControlName]="languages[i]" class="form__control" />
                            </div>
                        </p-tabPanel>
                    </p-tabView>
                </ng-container>
            </div>
        </div>
    </div>

    <div>
        <div class="form__item address-autocomplete-main-container">
            <app-address-autocomplete (addressChange)="onAddressAutocompleteChange($event)" (currentTimeZone)="onTimezoneChange($event)"></app-address-autocomplete>
            <div class="text-separator">
                <h2 class="title title--h2 fw-bold">
                    {{ 'app.clinic.form.complete_addr.title' | translate }}
                </h2>
            </div>

            <p class="ui-caption" data-color="600">
                {{ 'app.clinic.form.complete_addr.sub_title' | translate }}
            </p>
        </div>
        <div class="grid grid--address">
            <div class="grid__item">
                <div class="form__group">
                    <label class="form__item__label">{{ 'app.clinic.form.complete_addr.civic_addr_label' | translate }}</label>
                    <input class="form__control" type="text" formControlName="addressCivic" (keyup)="onAddressEdit()" />
                </div>
            </div>

            <div class="grid__item">
                <div class="form__group">
                    <label class="form__item__label">
                        {{ 'app.clinic.form.complete_addr.office_label' | translate }}
                        <span>({{ 'app.clinic.form.complete_addr.optional' | translate }})</span>
                    </label>
                    <input class="form__control" type="text" formControlName="addressOffice" (keyup)="onAddressEdit()" />
                </div>
            </div>

            <div class="grid__item">
                <div class="form__group">
                    <label class="form__item__label">{{ 'app.clinic.form.complete_addr.city_label' | translate }}</label>
                    <input class="form__control" type="text" formControlName="addressCity" (keyup)="onAddressEdit()" />
                </div>
            </div>

            <div class="grid__item">
                <div class="form__group">
                    <label class="form__item__label">{{ 'app.clinic.form.complete_addr.postal_code_label' | translate }}</label>
                    <input class="form__control" type="text" formControlName="addressPostalCode" (keyup)="onAddressEdit()" />
                </div>
            </div>

            <div class="grid__item">
                <div class="form__group">
                    <label class="form__item__label">{{ 'app.clinic.form.complete_addr.province_label' | translate }}</label>
                    <app-dropdown
                        class="dropdown dropdown--auto"
                        [options]="provinces"
                        formControlName="addressProvince"
                        placeholder="{{ 'app.clinic.form.complete_addr.placeholder' | translate }}"
                        (onChange)="onAddressEdit()"
                    ></app-dropdown>
                </div>
            </div>

            <div class="grid__item">
                <div class="form__group">
                    <label class="form__item__label">{{ 'app.clinic.form.complete_addr.country_label' | translate }}</label>
                    <app-dropdown
                        class="dropdown dropdown--auto"
                        [options]="countries"
                        formControlName="addressCountry"
                        placeholder="{{ 'app.clinic.form.complete_addr.placeholder' | translate }}"
                        (onChange)="onAddressEdit()"
                    ></app-dropdown>
                </div>
            </div>
            <div *ngIf="addressPreview" class="grid__item">
                <div class="text-separator">
                    <p>
                        {{ 'app.clinic.form.complete_addr.preview' | translate }}
                    </p>
                </div>

                <div class="addr-preview-container">
                    <p>{{ addressPreview.formatted }}</p>
                </div>
            </div>
            <div class="grid__item">
                <div class="form__group">
                    <label class="form__item__label">{{ 'app.clinic.form.timezone' | translate }}</label>
                    <app-dropdown class="dropdown dropdown--full" #tzSelect [options]="timezoneList" formControlName="selectedTimezone" [autoDisplayFirst]="false">
                        <ng-template let-timezone pTemplate="item">
                            {{ timezone.value.label }}
                            <span>({{ timezone.value.tz }})</span>
                        </ng-template>
                    </app-dropdown>
                </div>
            </div>

            <div class="grid__item">
                <div
                    class="form__group"
                    [ngClass]="{
                        'form__group--error': clinicForm.controls.email.touched && clinicForm.controls.email.errors
                    }"
                >
                    <label class="form__item__label">{{ 'app.clinic.form.email' | translate }}</label>
                    <input type="text" class="form__control" formControlName="email" />
                </div>
            </div>
        </div>
    </div>

    <div class="save-btn-container">
        <button type="button" class="btn btn--border" (click)="onCancelCreateClinic()">
            {{ 'app.clinic.form.btn.cancel' | translate }}
        </button>
        <button type="submit" class="btn" [ngClass]="{ 'btn--loading': isSendingClinicCreateForm }" [disabled]="clinicForm.invalid">
            {{ 'app.clinic.form.btn.save' | translate }}
        </button>
    </div>
</form>
