import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { switchMap, catchError, take } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { ClinicService } from '../services/clinic.service';
import { Clinic } from '../models/Clinic';
import { LanguageService } from '../services/language.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService, private clinicService: ClinicService, private languageService: LanguageService) {}

    canLoad(): Observable<boolean> {
        return this.authService.isAuthenticated$.pipe(take(1));
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.redirectIfUnauthenticated(state);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.redirectIfUnauthenticated(state);
    }

    private redirectIfUnauthenticated(state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.isAuthenticated$.pipe(
            switchMap((loggedIn) => {
                if (!loggedIn) {
                    this.authService.loginWithRedirect({ redirect_uri: window.location.href, ui_locales: this.languageService.getLang() });
                    return of(false);
                } else {
                    return this.authService.user$.pipe(
                        switchMap((user) => {
                            if (user.user_metadata.clinicIds && user.user_metadata.clinicIds[0]) {
                                const clinicId = localStorage.getItem('userClinic') ? localStorage.getItem('userClinic') : user.user_metadata.clinicIds[0];
                                return this.clinicService.getClinicById(clinicId.toString());
                            }
                            return of(null);
                        }),
                        switchMap((clinic: Clinic) => {
                            if (clinic) {
                                this.clinicService.setCurrentClinic(clinic);

                                return of(true);
                            } else {
                                if (state.url === '/clinicless') {
                                    return of(true);
                                }
                                this.router.navigate(['/clinicless']);
                                return of(false);
                            }
                        }),
                        catchError((_) => {
                            this.authService.loginWithRedirect({ redirect_uri: window.location.href, ui_locales: this.languageService.getLang() });
                            return of(false);
                        })
                    );
                }
            })
        );
    }
}
