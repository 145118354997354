import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, Type, Optional } from '@angular/core';
import { DrawerComponent } from './drawer.component';

@Injectable()
export class DrawerService {
    private drawerComponent: DrawerComponent;
    private container: HTMLElement;

    constructor(private resolver: ComponentFactoryResolver, private applicationRef: ApplicationRef, private injector: Injector) {
        this.container = document.body;
        this.drawerComponent = this.createDrawer();
    }

    open<T>(component: Type<T>, options?: any): T {
        if (!this.drawerComponent.isActive) {
            return this.drawerComponent.addDrawer<T>(component, options);
        }
    }

    close() {
        this.drawerComponent.onClose();
    }

    private createDrawer(): DrawerComponent {
        const componentFactory = this.resolver.resolveComponentFactory(DrawerComponent);

        const componentRef = componentFactory.create(this.injector);
        const componentRootNode = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        this.applicationRef.attachView(componentRef.hostView);

        componentRef.onDestroy(() => {
            this.applicationRef.detachView(componentRef.hostView);
        });
        this.container.appendChild(componentRootNode);

        return componentRef.instance;
    }
}
