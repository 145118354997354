<div class="info-banner" *ngIf="isVisible">
    <p class="info-banner__text" data-lang-fr="Pomelo fait maintenant partie de la famille TELUS Santé!" data-lang-en="Pomelo is now part of the TELUS Health family!"></p>
    <a
        data-theme="default"
        data-lang-fr="(Pour en savoir plus)"
        data-lang-en="(Learn more)"
        class="btn btn--no-border btn--sm info-banner__btn"
        href="{{ 'https://www.telus.com/' + lang + '/health/about-telus-health' }}"
        target="_blank"
        rel="noopener noopener"
    ></a>
    <span class="icon icon-close--sm" (click)="onClose()"></span>
</div>
